<template>

  <div class="oposList">


    <div class="loadingDiv" v-if="loading">Lade Daten...</div>

    <div v-else>

      <div class="row">
        <div class="col-12">
          <div class="btn-group mb-3 w-100">
            <div class="col-3"><button @click="getSEPAXml()" class="btn btn-primary btn-sm"><i class="fa-duotone fa-file-export"></i> Export SEPA XML <template v-if="Object.keys(selected).length > 0">({{Object.keys(selected).length}})</template><template v-else>(Alle)</template></button></div>
            <div class="col-3"><button @click="show('sepa')" :class="(showType === 'sepa') ? 'btn-primary' : 'btn-outline-primary'"  class="me-2 btn btn-outline-primary btn-sm"><i class="fa-duotone fa-filter-circle-dollar"></i> Zeige SEPA</button><button @click="show('all')" :class="(showType === 'all') ? 'btn-primary' : 'btn-outline-primary'" class="btn btn-outline-primary btn-sm"><i class="fa-duotone fa-filter-list"></i> Zeige Alle</button></div>

            <div class="col-6 text-end"><button @click="exportOpos('list')" class="btn me-3 btn-primary btn-sm"><i class="fa-duotone fa-file-lines"></i> Export OPOS-Liste</button><button @click="exportOpos('single')" class="btn btn-primary btn-sm me-3"><i class="fa-duotone fa-memo-circle-info"></i> Export OPOS-Buchungen</button>      <button @click="getAccountList()" class="btn btn-primary btn-sm "><i class="fa-duotone fa-file-user"></i> Export Konten</button></div>
            </div>

        </div>
      </div>
      <perfect-scrollbar>
      <table class="info">
        <thead>
        <tr>
          <th>SEPA</th>
          <th>Konto</th>
          <th>Titel</th>
          <th class="right">Saldo</th>
          <th class="right">
            <table class="belege">
              <tr>
                <td>Betrag</td>
                <td>Beleg</td>
              </tr>
            </table>
          </th>
        </tr>
        </thead>

       <tbody>


<template v-for="(opos, key) in oposList" >
        <tr :key="opos.id"  v-if="showType === 'all' || opos.sepa">
          <td><input type="checkbox" @change="toggleSelected(accounts[key].id)" :checked="selected[accounts[key].id]"></td>
            <td><template v-if="!opos.sepa">NEIN</template></td>
            <td>{{key}}</td>
          <td>{{accounts[key].title}}</td>
          <td class="right">{{$filters.priceEuro(opos.sum)}}</td>
          <td>
            <table class="belege">

              <tr :key="nr" v-for="(beleg, nr) in opos.items">
                <td>{{$filters.priceEuro(beleg.sum)}}</td>
                <td>{{nr}}</td>
              </tr>
<!--              <div class="buchungen">{{beleg.items}}</div>-->
            </table>
          </td>
        </tr>
</template>
       </tbody>

      </table>
      </perfect-scrollbar>
    </div>



  </div>

</template>

<script>


import axios from "axios";

export default {

  name: 'OposList',

  data() {

    return {
      loading:true,
      oposList: [],
      accounts: [],
      selected: {},
      showType: 'all'
    }

  },


 methods:{
   toggleSelected(id) {
     if (!this.selected[id]) {
       this.selected[id] = id;
     } else {
       delete this.selected[id];
     }
   },
   show(type) {
     this.showType = type;
   },
   loadOposList() {
     this.loading = true;
     const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
     axios.get(this.$store.state.apiUrl + "accounting/oposList", {headers}).then((r) => {
       this.oposList = r.data.result.data.data;
       this.accounts = r.data.result.data.accounts;
       this.loading = false;
     });
   },
   getSEPAXml() {

       this.loading = true;
       const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
     //convert selected to url string
     let selected = '';
     for (let key in this.selected) {
       selected += '&selected[]='+this.selected[key];
     }

     axios.get(this.$store.state.apiUrl + "accounting/oposList?xml=1"+selected, {headers}).then((r) => {


         if (r.data.result.data) {
         // force download zip file from base64
         const linkSource = 'data:text/xml;base64,' + r.data.result.data;

         let now = new Date();
          let fileName = 'export_sepa_'+now.getFullYear()+'-'+(now.getMonth()+1)+'-'+now.getDate()+'.xml';
         // download fileName
         // trigger download
         let a = window.document.createElement('a');
         a.setAttribute('href', linkSource);
         a.setAttribute('download', fileName);
         window.document.body.appendChild(a);
         a.click();
         }
         else {
           this.$store.dispatch("toastError", "Keine SEPA-Datei vorhanden");
         }

         this.loading = false;
       }).catch((e) => {

         this.$store.dispatch("handleApiResponse", e.response.data);

       });
   },
   getAccountList() {

     this.loading = true;
     const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
     axios.get(this.$store.state.apiUrl + "accounting/accountExport", {headers}).then((r) => {
       this.oposList = r.data.result.data;

       // force download zip file from base64
       const linkSource = 'data:text/csv;base64,' + r.data.result.data;

       let now = new Date();
       let fileName = 'export_sepa_'+now.getFullYear()+'-'+(now.getMonth()+1)+'-'+now.getDate()+'.csv';
       // download fileName
       // trigger download
       let a = window.document.createElement('a');
       a.setAttribute('href', linkSource);
       a.setAttribute('download', fileName);
       window.document.body.appendChild(a);
       a.click();


       this.loading = false;
     });
   },
   exportOpos(overView) {

       this.loading = true;
       const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
       axios.get(this.$store.state.apiUrl + "accounting/oposList?csv=1&overview="+overView, {headers}).then((r) => {

         // force download zip file from base64
         const linkSource = 'data:text/csv;base64,' + r.data.result.data;

         let now = new Date();
          let fileName = 'export_opostlist_'+now.getFullYear()+'-'+(now.getMonth()+1)+'-'+now.getDate()+'.csv';
         // download fileName
         // trigger download
         let a = window.document.createElement('a');
         a.setAttribute('href', linkSource);
         a.setAttribute('download', fileName);
         window.document.body.appendChild(a);
         a.click();


         this.loading = false;
       });
   }
 },


  created() {
    this.loadOposList();
  },
}

</script>
<style lang="scss" scoped>
.right, .belege td {
  text-align: right;
}
.oposList table {
  width: 100%;
}
.belege {
  width: 100%;
   td {
     width: 50%;
     padding:0 !important;
     background: none !important;
   }

  margin-bottom: 0;
}
.oposList table td,.oposList table th {
  padding:4px 10px
}
.oposList table tr:nth-child(even) td {
  background:#eeeeee
}
</style>
