<template>
  <div class="pluginItem activePlugin">
    <div class="singleRequest">
      <div class="plugin-item activePlugin">
        <Headline :legal="legalActive" v-if="legalActive"/>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <h6><i class="fa-duotone fa-square-info"></i> Details


          <button type="button" class="btn btn-outline-primary btn-sm float-end ms-3" @click="updateLegal()"><i
            class="fa-duotone fa-floppy-disk"></i> Speichern
          </button>
          <button type="button" class="btn btn-outline-danger btn-sm float-end ms-3"
                  @click="$store.commit('closeLegal');$store.commit('closeModal', 'legal');"><i
            class="fa-duotone fa-xmark fa-swap-opacity"></i> Abbrechen
          </button>
        </h6>
      </div>
      <div class="card-body">

        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label>Überschrift</label>
              <input class="form-control" type="text" v-model="d.title">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group"><label>Muss vom Kunde akzeptiert werden?</label>
              <select name="" class="form-select" v-model="d.acceptable">
                <option value="no">Nein</option>
                <option value="yes">Ja</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label>Gültig ab</label>
              <VueDatePicker v-model="d.valid_from" model-type="yyyy-MM-dd H:m:s" format="dd.MM.yyyy H:m" :placeholder="'Gültig ab'"  :auto-apply="true" :close-on-auto-apply="true" locale="de"    :enable-time-picker="true" cancel-text="X"  select-text="Wählen" :teleport="true"   >
              </VueDatePicker>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label style="display: block;">Typ:</label>
              <select v-model="d.type" style="width: 100%;">
<option v-for="cat in legalCats" :value="cat.id">{{cat.name}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <Editor2 v-model="d.text"  />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Headline from "@/routing/core/Legals/Layouts/Headline.vue";
import Editor2 from "@/components/Editor2.vue";
import {mapGetters} from "vuex";
import axios from "axios";
import VueDatePicker from "@vuepic/vue-datepicker";

export default {
  name: "LegalDetails",
  components: {
    VueDatePicker,
    Headline,
    Editor2
  },
  computed: {
    ...mapGetters(["getEmployees", "legalActive", "getConfigs"])
  },
  props: {
    draft: {},
    categories: [],
  },
  data() {
    return {
      d: this.draft,
      c: this.categories,
      isNew: true,
      legalCats: [{id: 'agb', name: 'Allgemeine Geschäftsbedingungen'}, {id: "dat", name: 'Datenschutzerklärung'}, {id: "wid", name: 'Widerrufsbelehrung'}, {id: "imp", name: 'Impressum'}, {id: "f01", name: 'Freier Vertrag #01'}, {id: "f02", name: 'Freier Vertrag #02'}, {id: "f03", name: 'Freier Vertrag #03'}],
    };
  },
  methods: {
    updateLegal() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};

        axios.post(this.$store.state.apiUrl + "legal/" + VueInner.d.id, VueInner.d, {headers}).then(() => {
          VueInner.$store.commit('closeLegal');
          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Vorlage wurde erfolgreich gespeichert');
          // Load Products
          VueInner.$store.dispatch('legalsFilter');
          this.$router.push({name: 'Legals'});
        }).catch(() => {
        });

    },

    checkIfIsNew() {
      if (this.legalActive && this.legalActive.id) {
        this.d = this.legalActive;
        this.isNew = false;
      } else {
        this.d = {
          title: "",
          text: "",
          type: "agb",
          acceptable: "no",
          valid_from: "",
        };
        this.isNew = true;
      }
    }
  },
  created() {
    this.checkIfIsNew();
  },
};
</script>
<style scoped lang="scss">
</style>
