<template>
  <Modal>
    <div class="modal show"
         aria-hidden="true" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <h6 class="modal-header">
            Sicherheitscode
            <button type="button" class="btn-close" @click="$emit('close')"></button>
          </h6>
          <div class="modal-body">
            <!-- Add Google 2 Factor Setup  -->
            <div class="form-group" v-if="twofactor === 'enabled'">
              <h3>Zwei-Faktor-Authentifizierung</h3>
              <p>Ist für dieses Konto bereits aktiviert</p>
              <button class="btn btn-outline-secondary" @click="turnOff"> Ausschalten </button>
            </div>
            <div class="form-group" v-if="twofactor === 'disabled'">
              <h3>Zwei-Faktor-Authentifizierung</h3>
              <p>Diese Funktion erlaubt es den  Authenticator für eine Zwei-Faktor-Authentifizierung zu nutzen. Zusätzlich zum Benutzernamen und Passwort wird dann ein vom Authenticator generierter sechs Zahlen langer Sicherheitscode benötigt, um sich auf der Website anmelden zu können. Der Sicherheitscode wird alle 30 Sekunden geändert. Dieses bietet ein Plus an Sicherheit gegen Hacker, die sich versuchen anzumelden, auch wenn sie in den Besitz der richtigen Zugangsdaten gekommen sind.</p>
              <h5>
                Schritt 1 - Authenticator installieren	</h5>
              <ul>
                <li>Google Authenticator</li>
                <li>Microsoft Authenticator</li>
              </ul>

              <h5>
                Schritt 2 - Einrichtung	</h5>

              <div class="span6">
                <p>
                  Die nachfolgenden Daten müssen in die Authenticator-Appeingegeben werden.		</p>
                <table class="table table-striped mb-3">
                  <tbody><tr>
                    <td>
                      Konto				</td>
                    <td>
                      {{getUser.name}}				</td>
                  </tr>
                  <tr>
                    <td>
                      Schlüssel				</td>
                    <td>
                      {{config.secret}}				</td>
                  </tr>
                  </tbody></table>
              </div>

              <div class="span6">
                <p>
                  Alternativ kann auch der angezeigte QR-Code mit dem Authenticator eingelesen werden.			<br>
                  <img :src="config.qr" alt="QR-Code" class="img-thumbnail" v-if="config.qr">
                </p>
            </div>
              <h5>Schritt 3 - Zwei-Faktor-Authentifizierung aktivieren</h5>
              <p>Um sicher zu gehen, dass alles richtig konfiguriert ist, muss der Sicherheitscode, der vom Google Authenticator ausgegeben wird, ins untenstehende Feld eingetragen werden. Bitte das Benutzerprofil danach speichern. Wenn der Sicherheitscode korrekt ist, wird die Zwei-Faktor-Authentifizierung aktiviert.</p>
              <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Sicherheitscode"
                       aria-describedby="basic-addon2" v-model="submitcode" @keydown.enter="updateQr">
                <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="updateQr">Aktivieren</button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";
export default {
  name: "TwoFactor",
  data() {
    return {
      showMenu: false,
      twofactor: 'disabled',
      submitcode: '',
      config: {
        secret: '',
        qr: '',
      }
    };
  },
  computed: {
    ...mapGetters(['getUser'])
  },
  components: {},
  methods: {
    checkQr() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + 'user/2fa', {headers}).then(response => {
        if (response.data.result.success) {
          this.twofactor = 'enabled';
        }
        else {
          this.twofactor = 'disabled';
          this.config = response.data.result.data;
        }
      });
    },
    updateQr() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.state.apiUrl + 'user/2fa', {code: this.submitcode, secret: this.config.secret}, {headers}).then(response => {
        if (response.data.result.success) {
          this.twofactor = 'enabled';
          this.$store.dispatch("toastSuccess", "Zwei-Faktor-Authentifizierung wurde deaktiviert");
        }
        else {
          this.$store.dispatch("handleApiResponse", response.response);
          this.twofactor = 'disabled';
          this.config = response.data.result.data;
        }
        this.$emit('close');
      });

    },
    turnOff() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.delete(this.$store.state.apiUrl + 'user/2fa', {headers}).then(response => {
        if (response.data.result.success) {
          this.twofactor = 'disabled';
          this.$store.dispatch("toastSuccess", "Zwei-Faktor-Authentifizierung wurde deaktiviert");
        }
        else {
          this.$store.dispatch("handleApiResponse", response.response);
          this.twofactor = 'enabled';
        }
        this.$emit('close');
      });
    }
  },
  mounted() {
    this.checkQr();
  }
};
</script>
<style scoped>
</style>
