<template>



  <div class="sidenav-card text-center" v-if="$store.state.publishNewVersion">
      <a   href="/" @click="$store.state.publishNewVersion = false;" class="button btn btn-success mb-3">Neue Version verfügbar!</a>
  </div>



  <p style="font-size: 10px; text-align: center">Version {{$store.state.version}}</p>


</template>
<script>
export default {
  name: "sidenav-card",
  props: ["textPrimary", "textSecondary", "iconClass"],
  data() {
    return {

    };
  }
};
</script>
