<template>
  <div class="card">
    <div class="card-header">
      <h6 class="card-title">Aktionen<button class="btn btn-outline-primary btn-sm float-end" @click='$store.commit("openModal", "document");'><i class="fa-duotone fa-pencil"></i>
      Auftrag bearbeiten
    </button></h6></div>
    <div class="card-body"></div>
  </div>
  <Modal v-if="modalsList.document">

    <Edit :document="documentActive" />

  </Modal>
</template>
<script>
import {mapGetters} from "vuex";
import Edit from "@/routing/core/Documents/Edit.vue";
export default {
  name: "Actions",
  components: {Edit},
  props: {

  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters(["getPlugins", "getEmployees", "documentActive", 'modalsList'])
  },

}
</script>
<style scoped>
</style>
