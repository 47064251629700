<template>
  <div class="appView queue products">
    <div class="productList">
          <div class=" product-list">
            <perfect-scrollbar style="max-height: 77vh;">
              <Sort @sort="resortOrder($event)" />
              <div class="product-item" v-for="queue in queueList.data" :key="queue.id">
                <div class="productLineWrap">
                  <div class="lineitem l-checkbox first">
                    <div class="checkbox"><input type="checkbox" :id="'id_'+queue.id" :value="queue.id"/><label :for="'id_'+queue.id"></label></div>
                  </div>
                  <div class="lineitem l-state">
                    <template v-if="queue.todo_state === 'finished'"><span tooltip="erledigt" position="right"><i class="fa-duotone fa-check-circle"></i></span></template>
                    <template v-if="queue.todo_state === 'stopped'"><span tooltip="gestoppt" position="right"><i class="fa-duotone fa-circle-xmark"></i></span></template>
                    <template v-if="queue.todo_state === 'executable'"><span tooltip="In der Warteschlange" position="right"><i class="fa-duotone fa-snake"></i></span></template>
                    <template v-if="queue.todo_state === 'queued'"><span tooltip="In Vorbereitung" position="right"><i class="fa-duotone fa-snake"></i></span></template>
                    <template v-if="queue.todo_state === 'failed'"><span tooltip="Fehlgeschlagen" position="right"><i class="fa-duotone fa-file-excel"></i></span></template>

                  </div>
                  <div class="lineitem l-titlesmall">

                    <p v-if="queue.todo_type === 'employee'" class="text-xs text-secondary mb-0">Mitarbeiter</p>
                    <p class="text-xs text-secondary mb-0" v-else>{{ queue.customerFormatted }} </p>
                  </div>
                  <div class="lineitem l-type l-icon">
                    <template v-if="queue.todo_type === 'document'">
                      <span tooltip="Belegversand" position="right"><i class="fa-duotone fa-file-contract"></i></span>
                    </template>
                    <template v-if="queue.todo_type === 'employee'">
                      <span tooltip="Nachricht an den Mitarbeiter" position="right"><i class="fa-duotone fa-id-card-clip"></i></span>
                    </template>
                    <template v-if="queue.todo_type === 'customer'">
                      <span tooltip="Nachricht an den Kunden" position="right"><i class="fa-duotone fa-users"></i></span>
                    </template>
                    <template v-if="queue.todo_type === 'task'">
                      <span tooltip="Nachricht über Ticketsystem" position="right"><i class="fa-duotone fa-clipboard-list-check"></i></span>
                    </template>

                  </div>
                  <div class="lineitem l-task l-icon">

                      <template v-if="queue.todo_task === 'sendMail'">
                        <template v-if="queue.payload && queue.payload.receivers && queue.payload.receivers.direct">
                          <span :tooltip="'E-Mail an '+queue.payload.receivers.direct" position="right"><i class="fa-duotone fa-envelope"></i></span>
                        </template>
                    <template v-else>
                        <span tooltip="E-Mailversand" position="right"><i class="fa-duotone fa-envelope"></i></span>
                    </template>
                      </template>
                      <template v-if="queue.todo_task === 'delete'"><span tooltip="Löschung" position="right"><i class="fa-duotone fa-trash"></i></span></template>

                  </div>
                  <div class="lineitem l-job l-datetime">
                    <span class="text-xs text-secondary" tooltip="geplanter Ausführungszeitpunkt" position="right"><i class="fa-duotone fa-timer"></i> {{ $filters.formatDate(queue.todo_time) }}<br>{{ $filters.formatTime(queue.todo_time) }}</span>
                  </div>
                  <div class="lineitem l-finished l-datetime">
                    <span class="text-xs text-secondary" tooltip="Ausführung" position="right">
                      <template v-if="queue.finish_time && queue.finish_time !== '0000-00-00 00:00:00'"><i class="fa-duotone fa-flag-checkered"></i> {{ $filters.formatDate(queue.finish_time) }}<br>{{$filters.formatTime(queue.finish_time)}}</template>
                      <template v-else><i class="fa-duotone fa-flag-checkered"></i> geplant</template>
                    </span>
                  </div>
                  <div class="lineitem l-date l-datetime">
                    <span class="text-xs text-secondary mb-0" tooltip="Erstellt" position="right"><i class="fa-duotone fa-calendar-plus"></i> {{ $filters.formatDate(queue.created_time) }}<br>{{ $filters.formatTime(queue.created_time) }}</span>
                  </div>
                  <div class="lineitem l-avatar">
                    <template v-if="queue.employee_created > 0">
                    <img :src="$filters.employeeIcon(getEmployees, queue.employee_created, 'id')"
                         class="sketchAvatar"></template>
                    <template v-else>
                      <img :src="$filters.generateSVGFromName(queue.customerFormatted)" class="sketchAvatar">
                    </template>
                  </div>
<div class="lineitem l-mails">
  <span tooltip="Betreff" position="right" v-if="queue.payload && queue.payload.subject">{{ queue.payload.subject}}</span>
  <span tooltip="Nachricht" position="right" v-else>{{queue.message}}</span>
</div>

                  <div class="lineitem l-action last"><button type="button" class="btn btn-outline-primary btn-xs" @click="setMail(queue);" :tooltip="this.$t('globals.view')" :position="'left'"><i
                    class="fa-duotone fa-eye"></i>
                  </button></div>
                </div>
              </div>
            </perfect-scrollbar>
            <pagination list="queues" listName="queueList" filterName="queuesFilter"/>
          </div>
    </div>
  </div>
  <Modal v-if="activeMail">
    <div class="modal  show" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ activeMail.payload.subject }}</h5>
            <button type="button" class="btn-close" @click="resetMail" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div v-html="activeMail.payload.body"></div>
            <div class="attachments" v-if="activeMail.payload.attachments.length">
              <h5> <i class="fa-duotone fa-paperclip"></i> {{$t("globals.attachments")}}</h5>
              <ul>
                <li v-for="(att, inn) in activeMail.payload.attachments">
                  <button class="btn" @click="openAttachmentFromMail(activeMail.customer.id, activeMail.id, inn)"><i class="fa-duotone fa-paperclip"></i>{{$t("globals.data")}} {{inn+1}}</button>
                </li>
              </ul>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" @click="resetMail"><i class="fa-duotone fa-xmark-circle"></i>{{$t("globals.close")}}</button>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<style>
.img-employee {
  width: 50px;
}
</style>
<script>
import axios from 'axios';
import {mapGetters} from "vuex";
import Pagination from "@/filter/components/pagination.vue";
import Sort from "@/routing/core/Queue/Layouts/Sort";
import Headline from "@/routing/core/Queue/Layouts/Headline.vue";

export default {
  name: 'QueueOverview',
  components: {
    Headline,
    Pagination,
    Sort
  },
  computed: {
    ...mapGetters(["getEmployees", "queueList", "queueActive"]),
  },
  methods: {
    resortOrder(payload) {
      if(payload.direction) {
        this.$store.state.filters.queues.ordering.value = payload.type;
        this.$store.state.filters.queues.direction.value = payload.direction;
      } else {
        this.$store.state.filters.queues.ordering.value = payload.type
      }
      this.$store.dispatch("queuesFilter");
    },
    /*
    setAbonnement(abonnement) {

      this.activeProduct = abonnement;
      //this.$router.push({name: "AbonnementDetails", params: {id: product.id}});

    },
    */
    openAttachmentFromMail(customerId, mailId, key) {

      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + customerId + "/mails/" + mailId + "/attachment/" + key, {headers}).then((r) => {

        const file = r.data.result.data;
        const linkSource = 'data:'+file.mimeType+';base64,' + file.file;

        // download fileName
        // trigger download
        var a = window.document.createElement('a');
        a.setAttribute('href', linkSource);
        a.setAttribute('download', file.filename);
        window.document.body.appendChild(a);
        a.click();
      });

    },
    setMail(mail) {
      this.activeMail = mail;
    },
    resetMail() {
      this.activeMail = null;
    },
    getQueues() {
    }
  },
  data() {
    return {
      queues: {},
      activeMail: null,
    };
  },
  created() {

    this.$store.dispatch("queuesFilter");
  },
}
</script>
