<template>
  <div class="card-body document-list">

      <p class="text-sm text-secondary" v-if="!documentList || documentList.data === 0">Es wurden keine Kunden
        gefunden.</p>

    <Sort @sort="resortOrder($event)" />
      <div :id="'document_'+document.id"  class="document-item"
           :class="(activeDocument && activeDocument.id === document.id) ? 'activeDocument' : 'notActive'"
           v-for="document in documentList.data" :key="document.id">
        <Headline :document="document"/>
      </div>
    <div class="pageCounter">
      <!--      <p>{{ counter + 20 }} / {{ $store.state.lists.document.complete }}</p>-->
    </div>
    <pagination list="documents" listName="documentList" filterName="documentsFilter"  />
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Documents/Layouts/Headline";
import Sort from "@/routing/core/Documents/Layouts/Sort";
import $ from "jquery";
import Pagination from "@/filter/components/pagination.vue";

export default {
  name: "DocumentsList",
  computed: {
    ...mapGetters(["getEmployees", "documentList", "documentActive"])
  },
  data() {
    return {
      selectedDocument: null,
      activeDocument: null,
      completeDocuments: 0,
      offset: 0,
      counter: 0,
    }
  },
  components: {
    Pagination,
    Headline, Sort
  },
  methods: {

    getDocuments(){
      this.$store.dispatch("documentsFilter");
    },
    resortOrder(payload) {
      if(payload.direction) {
        this.$store.state.filters.documents.ordering.value = payload.type;
        this.$store.state.filters.documents.direction.value = payload.direction;
      } else {
        this.$store.state.filters.documents.ordering.value = payload.type
      }
      this.getDocuments();
    },
    switchPage(type) {

      if (type === 'next' && this.documentList.offset <= this.documentList.completeDocuments) {
        this.$store.state.lists.documents.offset = this.documentList.offset + 20;
        this.getDocuments()
      }
      if (type === 'previous' && this.documentList.offset >= 20) {
        this.$store.state.lists.documents.offset = this.documentList.offset - 20;
        this.getDocuments()
      }
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  mounted() {
    this.getDocuments();
  },


};
</script>
