<template>

  <div class="accountData" :class="position">




        <div v-if="accountData && accountData.sorted">
          <div class="row">
            <div class="col-4">
               <span @click="contractToInvoice(accountData.sorted.key)" class="iconAction me-2 btn btn-sm btn-danger" tooltip="Nächsten Zeitraum verlängern">
      <i :class="'fas fa-rotate-right fa-duotone'"></i>
    </span>
              <span @click="downloadPDF(accountData.sorted.key)" class="iconAction btn btn-sm btn-danger" tooltip="Konto drucken">
      <i :class="'fas fa-print fa-duotone'"></i>
    </span>
         </div>
            <div class="col-8">
              <p class="hint pe-3" style="text-align: right"  v-if="accountData && accountData.sorted">{{accountData.sorted.account.title}}
              <template v-if="accountData.sorted.account.startSum"> (Startbetrag:
              <template v-if="accountData.sorted.account.type === 'bank' && accountData.sorted.account.startSum >= 0">+</template>
              <template v-if="accountData.sorted.account.type === 'bank' && accountData.sorted.account.startSum < 0">-</template>
              <template v-if="accountData.sorted.account.type !== 'bank' && accountData.sorted.account.startSum < 0">+</template>
              <template v-if="accountData.sorted.account.type !== 'bank' && accountData.sorted.account.startSum >= 0">-</template>
              {{ $filters.priceEuro(accountData.sorted.account.startSum) }})
              </template>
            </p>
            </div>
          </div>

          <div class="calcs row p-2 pt-0" style="max-width: 100%">
            <div class="calc col-lg-3 p-0">
              <p style="text-align: right; font-size: 14px;"><span style="font-size:10px">Konto</span><br>{{ accountData.sorted.key }} </p>
             </div>
            <div class="calc col-lg-3  p-0">
              <p style="text-align: right; font-size: 14px;"><span style="font-size:10px">Soll</span>     <br>{{ $filters.priceEuro(accountData.sorted.soll.sum) }}</p>
             </div>
            <div class="calc col-lg-3  p-0">
              <p style="text-align: right; font-size: 14px;"><span style="font-size:10px">Haben</span>    <br>{{ $filters.priceEuro(accountData.sorted.haben.sum) }}</p>
            </div>
            <div class="calc col-lg-3  p-0">
              <p style="text-align: right; font-size: 14px;"><span style="font-size:10px">Saldo</span><br> {{ $filters.priceEuro(accountData.sorted.diff.sum) }} {{accountData.sorted.diff.type}}</p>
            </div>
            <hr>
          </div> <perfect-scrollbar >
          <div class="calcs row p-2 pt-0" style="max-width: 100%">
            <div class="calc  p-0">

          <table  class="accountingDataTable table align-items-center p-2 mb-0" v-if="accountData.data && accountData.data.length > 0">
            <thead>
            <tr>
              <th>Belegdat.</th>
              <th>Belegfelder</th>
              <th class="align-right">Soll</th>
              <th class="align-right">Haben</th>
              <th class="align-right">Gegenkto</th>
              <th class="align-right" style="width: 25px">Aktion</th>
            </tr>
            </thead>
            <tbody>

            <template v-for="acc in accountData.data">
              <tr  :key="acc.id" v-if="acc.visible === 1 || acc.visible === '1'">
              <td>{{ $filters.formatDate(acc.datum) }}</td>

              <td>
                {{acc.beleg1}}
                <template v-if="acc.beleg2"><br><span class="hint">{{acc.beleg2}}</span></template>
              </td>

              <td class="align-right"><template v-if="acc.sh === 'S'">{{ $filters.priceEuro(acc.betrag) }}</template></td>
              <td class="align-right"><template v-if="acc.sh === 'H'">{{ $filters.priceEuro(acc.betrag) }}</template></td>
              <td class="align-right">{{acc.gegenkonto}}</td>
                <td class="align-right">
                  <div class="btn-group">
                  <button type="button" class="btn btn-xs btn-outline-danger" @click="duplicate(acc.id, 'reverse')" tooltip="Umkehrbuchung">U</button>
                  </div>
                </td>

              </tr>
            </template>

            </tbody>
          </table>

            </div>
          </div>
        </perfect-scrollbar>
        </div>



  </div>

</template>

<script>


import axios from "axios";

export default {

  name: 'AccountData',

  data() {

    return {}

  },

  props: {

    position: {

      type: String,

      required: true

    },
    accountData: {

      type: Object,

      required: true

    },
    isKonto: {
      type: Boolean,
      required: false
    }

  },


  created() {

  },
  methods: {
    downloadPDF(key) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.getters.getApiUrl + "accounting/pdf/account?accountingKey=" + key, {headers}).then((r) => {
        // force download zip file from base64
        const linkSource = 'data:application/pdf;base64,' + r.data.result.data;

        // download fileName
        // trigger download
        var a = window.document.createElement('a');
        a.setAttribute('href', linkSource);
        a.setAttribute('download', 'export_account_'+key+'.pdf');
        window.document.body.appendChild(a);
        a.click();

      }).catch((e) => {
        this.$store.dispatch("handleApiResponse", e.response);
      });
    },
    contractToInvoice(key) {

      let  payload = {accountingKey: key};

      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.getters.getApiUrl + "accounting/contractToInvoice", payload, {headers}).then((r) => {


        this.$emit("updateAccountData", {position: this.position, accountingKey:key});


        this.toast.success("Vorgang erfolgreich");
      }).catch((e) => {
        this.$store.dispatch("handleApiResponse", e.response);
      });
    }
  }
}

</script>
<style lang="scss" scoped>
.ps {
  height: 250px;
}
</style>
