<template>
  <div class="card">
    <div class="card-body hasSubtabs">
      <ul class="tabNav subtabs">

        <li v-for="(cat,key) in getPlugins.infos" :key="key"
            @click="setSubtab(key)"
            :class="(activeSubtab === key) ? 'active' : ''"><i class="fa-duotone" :class="cat.params.icon"></i> {{$t('infonav.'+key)}}
        </li>
        <li @click="setSubtab('files')"
            :class="(activeSubtab === 'files') ? 'active' : ''"><i class="fa-duotone fa-file"></i> {{$t('infonav.files')}}</li>
      </ul>
      <template v-if="activeSubtab === 'files'">
        <Component :is="'Files'"/>
      </template>
      <template v-if="activeSubtab === 'accessData'">
        <Component :is="'accessData'"/>
      </template>
      <template v-if="activeSubtab === 'mails'">
          <Component :is="'Mails'"/>
      </template>
      <template v-if="activeSubtab === 'activities'">
        <Component :is="'activities'"/>
      </template>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

import axios from "axios";
import Mails from "@/routing/core/Customers/Mails";
import Activities from "@/routing/core/Customers/Activity.vue";
import accessData from "@/routing/core/Customers/Passwords.vue";
import Files from "@/routing/core/Customers/Files.vue";
export default {
  name: "CustomerInformation",
  components: {
    Activities, Mails, accessData, Files
  },
  computed: {
    ...mapGetters(["customerActive", "getPlugins"]),
  },
  methods: {

    setSubtab(subtab) {
      this.activeSubtab = subtab;
      this.$router.push({
        name: 'CustomersTabsSubtabs',
        params: {id: this.customerActive.id, tab: this.$route.params.tab, subtab: subtab}
      })
    }
  },
  data() {
    return {

      activeSubtab: 'activities',
    };
  },
}
</script>
<style scoped>
</style>
