<template>
  <div class="sketch_content_wrap">
    <div class="sketch-row">
      <div class="columns large-12 medium-12 small-12"><h2 class="green">Einstellungen <span>Mitarbeiter-Module</span>
      </h2></div>
    </div>
    <!--    <div class="sketch-row">-->
    <!--      <div class="columns xlarge-push-3 large-push-3 xlarge-6 large-6 medium-12 small-12">-->
    <!--        <div class="card mb-3 config-card">-->
    <!--          <router-link to="config/global" class="config-link">-->
    <!--            <div class="card-body text-center">-->
    <!--              <i class="fa-duotone fa-cog fa-4x mb-3"></i>-->
    <!--              <h3>Globale Einstellungen</h3>-->
    <!--            </div>-->
    <!--          </router-link>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="configWrap green">
      <div class="sketch-row">
        <div class="columns large-12 medium-12 small-12"></div>
      </div>
      <div class="columns large-12 medium-12 small-12"><h3><i class="fa-duotone fa-user-gear"></i> Kategorie für
        Mitarbeiter</h3></div>
      <div class="columns xlarge-3 large-6 medium-12 small-12">
        <div class="card mb-3 config-card">
          <router-link to="/config/employee/timeconfigs" class="config-link">
            <div class="card-body text-center">
              <i class="fa-duotone fa-calendar-alt fa-3x mb-3"></i>
              <h3>Arbeitstage verwalten</h3>
            </div>
          </router-link>
        </div>
      </div>
      <div class="columns xlarge-3 large-6 medium-12 small-12">
        <div class="card mb-3 config-card">
          <router-link to="/config/employee/categories" class="config-link">
            <div class="card-body text-center">
              <i class="fa-duotone fa-users fa-3x mb-3"></i>
              <h3>Kategorien verwalten</h3>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ConfigEmployee"
}
</script>
<style scoped>
</style>
