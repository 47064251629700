<template>
  <div class="appView plugins dfb plugin-list">

      <div class="row mb-3">
        <div class="col-12">
          <ul class="tabNav">
            <li @click="activeView ='requests'" :class="(activeView === 'requests') ? 'active' : '' ">
              Bewerbungen
            </li>
            <li @click="activeView ='requestTypes'" :class="(activeView === 'requestTypes') ? 'active' : '' ">
              Bewerbungsphasen
            </li>
          </ul>
        </div>
      </div>
    <component :is="activeView"/>

  </div>
</template>
<script>
import CalcTypes from "@/routing/plugins/dfb/subs/CalcTypes";
import Requests from "@/routing/plugins/dfb/subs/Requests";
import RequestTypes from "@/routing/plugins/dfb/subs/RequestTypes";
import Questions from "@/routing/plugins/dfb/subs/Questions";

export default {
  name: "DFBDashboard",
  components: {
    CalcTypes, Requests, RequestTypes, Questions
  },
  methods: {
    setProduct(product) {
      this.activeProduct = product;
    },
  },
  data() {
    return {
      activeView: 'requests',
    };
  },
  created() {
  },
};
</script>
<style lang="scss">
</style>
