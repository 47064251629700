
<template>
  <div class="draftLineWrap" :class="($store.state.activeItems && $store.state.activeItems.ConfigAccountingKeys && $store.state.activeItems.ConfigAccountingKeys.id === draft.id) ? 'active' : ''" >
    <div class="lineitem first l-checkbox">
      <div class="checkbox">
        <input type="checkbox"
               :id="'id_'+draft.id"
               :value="draft.id"
               v-if="draftList && draftList.selected"
               v-model="draftList.selected[draft.id]"/>
        <label :for="'id_'+draft.id"></label>
      </div>
    </div>

    <div class="lineitem l-unit">
      {{ draft.key }}
    </div>

    <div class="lineitem l-title" @click="setConfig(draft)">
      {{ draft.title }}
    </div>


    <div class="lineitem l-number">
      {{ draft.budget }}
    </div>

    <div class="lineitem l-number">
      {{ draft.einnahme }}
    </div>
    <div class="lineitem l-number">
      {{ draft.type}}
    </div>
    <div class="lineitem l-description ml-auto">
      {{ draft.description }}
    </div>
    <div class="lineitem last ">
      <template v-if="$store.state.activeItems && $store.state.activeItems.ConfigAccountingKeys && $store.state.activeItems.ConfigAccountingKeys.id === draft.id">
      <i class="fa-duotone fa-x fa-swap-opacity" @click="$store.commit('closeConfigAccountingKeys');"></i>
    </template>
      <template v-else><i class="fa-duotone fa-chevrons-up"  @click="setConfig(draft)"></i></template>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "Headline",
  props: {
    draft: Object,
  },
  computed: {
    ...mapGetters(["getEmployees"])
  },
  methods: {
    setConfig(draft) {
      const newData = {data: draft, type:'ConfigAccountingKeys'};
      this.$store.dispatch("setActiveConfig",  newData).then(()=> {
      this.$router.push({name: 'ConfigAccountingKeysDetails', params: {id: draft.id}})
    });
    },
  }
}
</script>
