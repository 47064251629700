<template>
  <div class="card">
    <div class="card-header">
      <button class="checkCircleBtn btn-outline-primary btn btn-sm" @click="checkTask(taskItem.id)"><i
        class="fa-duotone fa-check fa-xl"></i></button>

      <button type="button" class="btn-close float-end" aria-label="Close" @click="closeTask"></button>

      <h6 class="editLine">{{ taskItem.title }}</h6>
    </div>
    <ul class="tabNav">
      <li v-for="cat in taskTabs" :key="cat.name"
          @click="changeTab(cat.name)"
          :class="(activeSubtab === cat.name) ? 'active' : ''">
        <template v-if="cat.name === 'comments' && taskItem._countComments" ><span class="incNumber">{{ taskItem._countComments }}</span></template>
        <template v-if="cat.name === 'attachments' && taskItem._countAttachments " ><span class="incNumber">{{ taskItem._countAttachments }}</span></template>
        <template v-if="cat.name === 'subtasks' && taskItem._countIssues" ><span class="incNumber">{{ taskItem._countIssues }}</span></template>
        <template v-if="cat.name === 'timesheet' && taskItem.times && taskItem.times.sumTask" ><span class="incNumber" style="width: auto;">{{ taskItem.times.sumTask.toFixed(0) }}</span></template>
        <i class="fa-duotone" :class="cat.icon"></i>
        {{ cat.label }}

      </li>
    </ul>
    <div class="card-header card-header-inline" v-if="activeSubtab === 'details'">
      <div class="row" >
        <div class="col-9"><h6 class="editLine" contenteditable @keypress.enter.prevent="editItem('title')"
                               @blur="editItem('title')">{{taskItem.title }}</h6></div>
        <div class="col-3 text-end">
          <div class="btn-group float-end">
            <button class="backToParent btn-outline-primary btn btn-sm " @click="$router.push({ name: 'TasksTabs', params: { id: taskItem.parent_issue, tab: 'subtasks' } })" v-if="taskItem && taskItem.parent_issue"><i
              class="fa-duotone fa-left-to-line"></i> <span tooltip="Zurück zum Haupttask">Zurück</span></button>
            <button type="button" class="btn btn-outline-secondary btn-sm" @click="deleteTask(taskItem.id)"><i
              class="fa-duotone fa-trash"></i> {{$t("tasks.deleteTask")}}
            </button>
          </div>

        </div>
      </div>
    </div>
    <div class="card-body" v-if="activeSubtab === 'details'">
      <div class="taskDetails">
        <div class="infoBlock text-xs mb-2"><i
          class="fa-duotone fa-calendar-circle-plus"></i> {{$t("tasks.details.created")}}
          {{ $filters.formatDate(taskItem.crm_created_date) }} |
          <i
            class="fa-duotone fa-calendar-circle-user"></i> {{$t("tasks.details.processed")}}
          {{ $filters.formatDateTime(taskItem.crm_modified_date) }}
        </div>
        <div class="row mb-2">
          <div class="col-lg-9 col-xl-10 col-12">
            <div class="topInfos">
              <div class="row">
                <div class="col">
                  <label class="color">{{$t("globals.customer")}}</label>

                  <template v-if="taskItem.customer && taskItem.customer.id">
                    <template v-if="taskItem.customer.c_company">
                      {{ taskItem.customer.c_company }}
                      <br><span class="text-xs customerInfo"
                                v-if="taskItem.customer.c_title || taskItem.customer.c_firstname || taskItem.customer.c_lastname">
           <template v-if="taskItem.customer.c_title">{{ taskItem.customer.c_title }}&nbsp;</template>
          <template v-if="taskItem.customer.c_firstname">{{ taskItem.customer.c_firstname }}&nbsp;</template>
          <template v-if="taskItem.customer.c_lastname">{{ taskItem.customer.c_lastname }}</template>
              </span>
                    </template>
                    <template v-else>
                      <template v-if="taskItem.customer.c_title">{{ taskItem.customer.c_title }}&nbsp;</template>
                      <template v-if="taskItem.customer.c_firstname">{{
                          taskItem.customer.c_firstname
                        }}&nbsp;
                      </template>
                      <template v-if="taskItem.customer.c_lastname">{{ taskItem.customer.c_lastname }}</template>
                    </template>
                  </template>
                <template v-else>
                    <customersearch v-model="taskItem.customer" @update:model-value="correctId('crm_customer_id')" class="small" />
                  </template>
                </div>
                <div class="col">
                  <label class="color">{{$t("globals.project")}}</label>
                  <template v-if="editProject">
                    <div style="position: relative;">
                      <button class="btn-close" @click="editProject = false;" style="
    z-index: 100000000000000000000;
    position: absolute;
    top: 4px;
    right: 4px;"></button>
                      <VueMultiselect class="small" :options="projects" v-model="taskItem.project_id" :multiple="false"
                                      :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                                      ref="multiselect_project" noOptions="Keine Projekte bisher angelegt"
                                      :placeholder="this.$t('projects.chooseProject')" label="title" :track-by="'id'"
                                      :select-label="this.$t('projects.chooseProject')" :taggable="false"
                                      @update:model-value="correctId('project_id'); editProject = false;"
                      ></VueMultiselect>
                    </div>
                  </template>
                  <template v-else>
                    <span v-if="taskItem.project"  style="cursor: pointer;"
                          @click="editProject = true;$nextTick(() => $refs.multiselect_project.activate())">{{
                        taskItem.project.title
                      }}</span>
                    <button v-else class="btn btn-sm btn-outline-primary"
                            @click="editProject = true;$nextTick(() => $refs.multiselect_project.activate())"><i
                      class="fa-duotone fa-plus"></i> {{$t("tasks.details.add")}}
                    </button>
                  </template>
                </div>
                <div class="col">
                  <label class="color">{{$t("globals.status")}}</label>
                  <template v-if="editState">
                    <div style="position: relative;">
                      <button class="btn-close" @click="editState = false;" style="
    z-index: 100000000000000000000;
    position: absolute;
    top: 4px;
    right: 4px;"></button>
                      <VueMultiselect class="small open" :options="Object.values(getConfigs.issue_states)"
                                      v-model="taskItem.state_id"
                                      :multiple="false" :valueProp="'id'" ref="multiselect_state"
                                      :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                                      :isOpen="true" track-by="title" openDirection="bottom"
                                      :placeholder="this.$t('tasks.details.chooseStatus')" label="title" :allow-empty="false"
                                      :select-label="this.$t('tasks.details.chooseStatus')" @update:model-value="correctId('state_id')"
                      ></VueMultiselect>
                    </div>
                  </template>
                  <template v-else>
                  <span
                    @click="editState = true;$nextTick(() => $refs.multiselect_state.activate())" style="cursor: pointer;">{{
                      getTitleById(taskItem.state_id, "issue_states")
                    }}</span>
                  </template>
                  <template v-if="isRejectable(taskItem.state_id)">
                    <p>In <input type="number" @blur="updateTask" v-model="taskItem.repeatDays" class="form-control form-control-sm form-control-plaintext small"/> Tagen wiederholen</p>
                  </template>
                </div>
                <div class="col">
                  <label class="color">{{$t("globals.priority")}}</label>

                  <template v-if="editPrio">
                    <div style="position: relative;">
                      <button class="btn-close" @click="editPrio = false;" style="
    z-index: 100000000000000000000;
    position: absolute;
    top: 4px;
    right: 4px;"></button>
                      <VueMultiselect class="small open" :options="Object.values(getConfigs.issue_prios)"
                                      v-model="taskItem.priority_id"
                                      :multiple="false" :valueProp="'id'" ref="multiselect_prio"
                                      :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                                      :isOpen="true" track-by="title" openDirection="bottom"
                                      :placeholder="this.$t('projects.choosePriority')" label="title" :allow-empty="false"
                                      :select-label="this.$t('projects.choosePriority')" @update:model-value="correctId('priority_id')"
                      ></VueMultiselect>
                    </div>
                  </template>
                  <template v-else>
                    <p>
                <span
                  @click="editPrio = true;$nextTick(() => $refs.multiselect_prio.activate())" style="cursor: pointer;">{{
                    getTitleById(taskItem.priority_id, "issue_prios")
                  }}</span>
                    </p>
                  </template>
                </div>
              </div>
            </div>
            <hr>
            <label class="color">{{$t("globals.description")}}</label>
           <Editor2 class="taskDesc" v-model="description" @blur="updateTask"></Editor2>
            <hr>
            <div class="row infoRow">
              <div class="col">
                <template v-if="!editStart">
                  <label class="color">{{$t("globals.start")}}</label>
                  <span @click="editStart = true;"><i
                    class="fa-duotone fa-edit"></i> {{ $filters.formatDate(taskItem.start_date) }} </span>
                </template>
                <template v-else>
                  <label class="color">{{$t("globals.start")}}</label>
                  <VueDatePicker class="small" model-type="yyyy-MM-dd" format="dd.MM.yyyy"
                                 :placeholder="this.$t('tasks.details.chooseStart')" :auto-apply="true" :close-on-auto-apply="true"
                                 locale="de" :enable-time-picker="false" cancel-text="X" :select-text="this.$t('globals.choose')"
                                 v-model="taskItem.start_date" @update:model-value="updateTask"></VueDatePicker>
                </template>
              </div>
              <div class="col">
                <template v-if="!editDue">
                  <label class="color">{{$t("tasks.create.submissionDate")}}</label>
                  <span @click="editDue = true;"><i
                    class="fa-duotone fa-edit"></i>  {{ $filters.formatDate(taskItem.due_date) }}</span>
                </template>
                <template v-else>
                  <label class="color">{{$t("tasks.create.submissionDate")}}</label>
                  <VueDatePicker class="small" model-type="yyyy-MM-dd" format="dd.MM.yyyy"
                                 :placeholder="this.$t('projects.chooseSubmission')" :auto-apply="true" :close-on-auto-apply="true"
                                 locale="de" :enable-time-picker="false" cancel-text="X" :select-text="this.$t('globals.choose')"
                                 v-model="taskItem.due_date" @update:model-value="updateTask"></VueDatePicker>

                </template>
              </div>

                <div class="col">
                  <label class="color">{{$t("tasks.details.timeExposure")}}</label>
                  <input type="number" class="form-control hoverShowInput" v-model="taskItem.estimated_hours"
                         @blur="updateTask"
                         style="width: 75px; display: inline-block; margin-left: 0; margin-right: 10px;"/>
                </div>


              <div class="externalResource mb-3 col">
                <label class="color">{{$t("tasks.taskNr")}}</label>
                <span>{{taskItem.id}}/{{ taskItem.hash }}</span>
                <span v-if="taskItem.external_ressource"><br>{{ taskItem.external_ressource }}</span>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-xl-2 col-12 rightCol">
            <label class="color">{{$t("tasks.details.assignedTo")}}</label>
            <template v-if="!editAssignee">
              <p @click="editAssignee = true; $nextTick(() => $refs.multiselect.activate()) " style="cursor: pointer;"><img style="max-width: 40px" v-if="getEmployees && getEmployees.length && taskItem && taskItem.employee_id"
                      :src="$filters.employeeIcon(getEmployees, taskItem.employee_id, 'id')"
                      class="sketchAvatar"><template v-if="taskItem && taskItem.employee_id">&nbsp;{{ $filters.employeeName(getEmployees, taskItem.employee_id) }}</template>
                <template v-else>
                  -
                </template></p>
            </template>
            <template v-else>
              <div style="position: relative;">
                <button class="btn-close" @click="editAssignee = false;" style="
    z-index: 100000000000000000000;
    position: absolute;
    top: 4px;
    right: 4px;"></button>
                <VueMultiselect class="small open" :options="getEmployees" v-model="taskItem.employee_id"
                                :multiple="false" :valueProp="'id'" ref="multiselect"
                                :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                                track-by="e_name" :isOpen="true"
                                :placeholder="this.$t('tasks.details.chooseEditor')" label="e_name" :allow-empty="false"
                                :select-label="this.$t('tasks.details.chooseEditor')" @update:model-value="correctId('employee_id')"
                ></VueMultiselect>
              </div>
            </template>
            <label class="color">{{$t("tasks.details.createdBy")}}</label>
            <p v-if="taskItem.crm_created_employee_id">  <img style="max-width: 40px"
                    :src="$filters.employeeIcon(getEmployees, taskItem.crm_created_employee_id, 'id')"
                    class="sketchAvatar"> {{ $filters.employeeName(getEmployees, taskItem.crm_created_employee_id) }}</p>
            <p v-else>
              <template v-if="taskItem.author">
              <img style="max-width: 40px"
                           :src="$filters.generateSVGFromName(taskItem.author)"
                           class="sketchAvatar"> {{taskItem.author}}</template>
            <template v-else>{{$t("globals.customer")}}</template>
            </p>
            <label class="color">{{$t("globals.tracker")}}</label>
            <template v-if="editTracker">
              <div style="position: relative;">
                <button class="btn-close" @click="editTracker = false;" style="
    z-index: 100000000000000000000;
    position: absolute;
    top: 4px;
    right: 4px;"></button>
                <VueMultiselect class="small open" :options="Object.values(getConfigs.issue_trackers)"
                                v-model="taskItem.tracker_id"
                                :multiple="false" :valueProp="'id'" ref="multiselect_tracker"
                                :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                                :isOpen="true" track-by="title" openDirection="bottom"
                                :placeholder="this.$t('tasks.details.chooseTracker')" label="title" :allow-empty="false"
                                :select-label="this.$t('tasks.details.chooseTracker')" @update:model-value="correctId('tracker_id')"
                ></VueMultiselect>
              </div>
            </template>
            <template v-else>
              <template v-if="!taskItem.tracker_id">
                <button class="btn btn-outline-primary btn-sm mb-2 mt-2"
                        @click="editTracker = true;$nextTick(() => $refs.multiselect_tracker.activate())"><i
                  class="fa-duotone fa-plus"></i></button>
              </template>
              <p v-else
                    @click="editTracker = true;$nextTick(() => $refs.multiselect_tracker.activate())" style="cursor: pointer;">{{
                  getTitleById(taskItem.tracker_id, "issue_trackers")
                }}</p>
            </template>
            <label class="color"  >{{$t("tasks.details.contactPerson")}}</label>
            <p v-if="!editExtFollower" @click="editExtFollower = true;$nextTick(() => $refs.multiselect_extfollower.activate())" style="cursor: pointer;">
                    <template v-if="taskItem.followers && taskItem.followers.outer && taskItem.followers.outer.length">
                      <template v-for="follower in taskItem.followers.outer">
                        <span style="display: block;">{{ follower._customerName }}</span>
                      </template>
                    </template>
                    <template v-else>

                    <template v-if="taskItem.customer">
                    <template v-if="taskItem.customer.c_firstname">{{ taskItem.customer.c_firstname }}&nbsp;</template>
                    <template v-if="taskItem.customer.c_lastname">{{ taskItem.customer.c_lastname }}</template>
                      </template>




                  </template>
            <template v-if="!taskItem.followers || !taskItem.followers.outer.length === 0">
                <button class="btn btn-outline-primary btn-sm mb-2 mt-2"><i class="fa-duotone fa-plus"></i></button>
              </template>
            </p>

            <template v-if="editExtFollower">
              <div style="position: relative;">
                <button class="btn-close isMultiselect" @click="editExtFollower = false;" style="
    z-index: 100000000000000000000;
    position: absolute;
    top: 4px;
    right: 4px;"></button>
                <VueMultiselect class="small open isMultiselect" :options="Object.values(addresses)"
                                v-model="taskItem.followers.outer"
                                :multiple="true" :valueProp="'id'" ref="multiselect_extfollower"
                                :close-on-select="false" :clear-on-select="false" :preserve-search="true"
                                :isOpen="true" track-by="id" openDirection="bottom" @close="editExtFollower = false; updateTask()"
                                :placeholder="this.$t('tasks.details.chooseContactPerson')" label="_customerName" :showNoOptions="false"
                                :select-label="this.$t('tasks.details.chooseContactPerson')"
                ></VueMultiselect>
              </div>
            </template>
            <label class="color">{{$t("globals.employee")}}</label>
            <p @click="editIntFollower = true;$nextTick(() => $refs.multiselect_intfollower.activate())" style="cursor: pointer;"
                  v-if="!editIntFollower">
                    <template v-if="taskItem.followers && taskItem.followers.inner">
                      <template v-for="follower in taskItem.followers.inner">
                        <span style="display: block;">{{ follower.e_name }}</span>
                      </template>
                    </template>
              <template v-if="!taskItem.followers || !taskItem.followers.inner.length">
                <button class="btn btn-outline-primary btn-sm mt-2"><i class="fa-duotone fa-plus"></i></button>
              </template>
                     </p>
            <template v-if="editIntFollower">
              <div style="position: relative;">
                <button class="btn-close isMultiselect" @click="editIntFollower = false;" style="
    z-index: 100000000000000000000;
    position: absolute;
    top: 4px;
    right: 4px;"></button>
                <VueMultiselect class="small open isMultiselect" :options="Object.values(getEmployees)"
                                v-model="taskItem.followers.inner"
                                :multiple="true" :valueProp="'id'" ref="multiselect_intfollower"
                                :close-on-select="false" :clear-on-select="false" :preserve-search="true"
                                :isOpen="true" track-by="id" openDirection="bottom"  @close="editIntFollower = false;updateTask()"
                                :placeholder="this.$t('tasks.details.chooseEmployee')" label="e_name" :allow-empty="true"
                                :select-label="this.$t('tasks.details.chooseEmployee')"
                ></VueMultiselect>
              </div>
            </template>
            <p>
              <br>
              <a v-if="taskItem.linkPlanio" :href="taskItem.linkPlanio" target="_blank" class="btn btn-outline-primary btn-sm"><i
                class="fa-duotone fa-square-arrow-up-right"></i>{{$t("tasks.details.openExternal")}}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="taskAttachments" v-if="activeSubtab === 'attachments'">
      <Attachments :attachments="taskItem.attachments" v-if="taskItem.attachments" @update="getSingleTask"></Attachments>
    </div>
    <div class="subTasks" v-if="activeSubtab ==='subtasks'">
      <Subtasks :task="taskItem" v-if="taskItem"></Subtasks>
    </div>
    <div class="taskComments" v-if="activeSubtab === 'comments'">
      <Comments :taskId="taskItem.id" v-if="taskItem.id"/>
    </div>
    <div class="timeSheet" v-if="activeSubtab === 'timesheet'">
      <Timesheet :taskId="taskItem.id" v-if="taskItem.id" @update="getSingleTask"/>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Comments from "@/routing/core/Tasks/Comments.vue";
import axios from "axios";
import Editor2 from "@/components/Editor2.vue";
import Attachments from "@/routing/core/Tasks/Layouts/Attachments.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import VueMultiselect from 'vue-multiselect';
import Subtasks from "@/routing/core/Tasks/Layouts/Subtasks.vue";
import Timesheet from "@/routing/core/Tasks/Layouts/Timesheet.vue";
import customersearch from "@/filter/components/customersearch.vue";
import $ from "jquery";

export default {
  name: "Details",

  components: {
    Comments, Editor2, Attachments, VueDatePicker, VueMultiselect, Subtasks, Timesheet, customersearch
  },
  computed: {
    ...mapGetters(["customerActive", "getEmployees", "modalsList", "taskActive", "getConfigs"])
  },
  data() {
    return {
      taskItem: {},
      editorVisible: true,
      taskTabs: [
        {name: 'details', label:this.$t('tasks.labels.details'), icon: 'fa-info'},
        {name: 'subtasks', label:this.$t('tasks.labels.subtasks'), icon: 'fa-list-tree'},
        {name: 'attachments', label:this.$t('tasks.labels.attachments'), icon: 'fa-paperclip'},
        {name: 'comments', label:this.$t('tasks.labels.comments'), icon: 'fa-comments'},
        {name: 'timesheet', label:'Zeitaufwand', icon: 'fa-stopwatch'},
      ],
      activeSubtab: 'details',
      description: '',
      editStart: false,
      editDue: false,
      editAssignee: false,
      editState: false,
      editPrio: false,
      editTracker: false,
      editExtFollower: false,
      editIntFollower: false,
      editProject: false,
      editCustomer: false,
      addresses: [],
      projects: []
    }
  },
  methods: {
    closeTask() {
      this.$store.dispatch("tasksFilter");
      this.$store.dispatch('setTask', null);
      this.$router.push({name: "Tasks"});
    },
    isRejectable($id) {

      let a;
      for (a in this.getConfigs.issue_states) {
        if (this.getConfigs.issue_states[a].id === $id) {
          return this.getConfigs.issue_states[a].state === 'reject';
        }
      }
    },
    getSingleTask() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + 'issue/' + this.$route.params.id, {headers})
        .then(response => {
          this.taskItem = response.data.result.data;
          if(this.taskItem.description){
          this.description = this.$filters.renderLineBreaks(this.taskItem.description);
          } else {
            this.description = '';
          }
          this.$store.dispatch('setTask', this.taskItem);
          this.getAddresses();
          this.getProjects();
         this.scrollToTop();
        })
        .catch(e => {

          console.log(e);
        });
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
    getProjects() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "project/search?filter_customer=" + this.taskItem.customer.id + "&filter_state=active", {headers}).then((r) => {
        this.projects = r.data.result.data;
      });
    },
    getTitleById(id, type) {
      return Object.values(this.getConfigs[type]).find(issueState => issueState.id === id)?.title || 'keine';
    },
    getAddresses() {

      if(this.taskItem.crm_customer_id > 0){

      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.taskItem.crm_customer_id + "/addresses", {headers}).then((r) => {
        this.addresses = r.data.result.data;
      });
      }
    },
    correctId(key) {
      this.taskItem[key] = this.taskItem[key].id;
      this.updateTask();

    },
    editItem(field) {
      const value = event.target.innerText;
      this.taskItem[field] = value;
      this.updateTask();
    },
    updateTask() {
      this.taskItem.description = this.description;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const hash = this.taskItem.customer.hash;
      let postContent = {
        data: this.taskItem,
        hash: hash
      }
      axios.put(this.$store.state.apiUrl + 'issue/' + this.taskItem.id, postContent, {headers})
        .then(response => {
          this.editStart = false;
          this.editDue = false;
          this.editAssignee = false;
          this.editState = false;
          this.editPrio = false;
          this.editTracker = false;
          this.editExtFollower = false;
          this.editIntFollower = false;
          this.editProject = false;
          this.editCustomer = false;
          this.taskItem = response.data.result.data;
          this.description = this.taskItem.description;
          this.$store.dispatch('setTask', this.taskItem);

        })
        .catch(e => {
          this.$store.dispatch("handleApiResponse", e);
        });
    },
    checkTask() {
      // todo-ralf: correct state_id after API is ready
      this.taskItem.state_id = 2;
      this.updateTask();
      this.$router.push({name: "Tasks"});
      //this.$store.dispatch("tasksFilter");
      // Await refresh then this.closeTask
      setTimeout(() => {
        this.closeTask();
      }, 1000);
    },
    deleteTask(id) {
      this.$confirm(this.$t('tasks.details.detailsTextDelete'), this.$t('tasks.deleteTask'), {
        confirmButtonText: this.$t('globals.yes'),
        cancelButtonText: this.$t('globals.no'),
        type: 'warning'
      }).then(() => {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + 'issue/' + id, {headers})
          .then(response => {
            this.$store.dispatch('setTask', null);
            this.$store.dispatch("tasksFilter");
            this.$router.push({name: "Tasks"});
            this.$store.dispatch('toastSuccess', this.$t('toast.success.taskDeleted'));
          })
          .catch(e => {
            this.$store.dispatch("handleApiResponse", e.response);
          });
      }).catch(e => {
        console.log(e);
      });
    },
    setActiveSubtabFromRoute() {
      const { tab } = this.$route.params;
      if (tab && this.isValidTab(tab)) {
        this.activeSubtab = tab;
      } else {
        this.activeSubtab = 'details';
      }
    },
    isValidTab(tab) {
      return this.taskTabs.some((taskTab) => taskTab.name === tab);
    },
    changeTab(tabName) {
      this.activeSubtab = tabName;
      this.$router.push({ name: 'TasksTabs', params: { id: this.$route.params.id, tab: tabName } });
    },
    hideEditor() {
      this.editorVisible = false;
    },
    showEditor() {
      this.editorVisible = true;
    },
  },
  created() {
    this.getSingleTask();
    this.setActiveSubtabFromRoute();
    window.addEventListener("triggerReloadFiles", this.getSingleTask);
  },
  mounted() {
    Pusher.logToConsole = false;
    var pusher = new Pusher('d4e91a37c1c6462058ae', {cluster: 'eu'});
    let VueInner = this;
    var channel = pusher.subscribe('42Aj4EUnVYfqZd8F8MHtBjve549pV6GdwVUu4qZJLjtvfRTApv77jMfm9CZ7zTUE');
    channel.bind('issue-'+this.$route.params.id, function (data) {
      VueInner.getSingleTask();
    });
  },
  watch: {
    taskActive() {
    },
    $route(to) {
      if (to.params.tab) {
        this.activeSubtab = to.params.tab;
      } else {
        this.activeSubtab = 'details';
      }
      if (to.params.id) {
        this.getSingleTask();
      }
    }
  },
}
</script>
<style>
.multiselect.small.open .multiselect__select:before {
  display: none;
}
.col-lg-3.col-xl-2.col-12.rightCol p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
