<template>
  <div class="card">
    <div class="card-header card-header-inline">
      <h6 class="editLine" v-if="taskActive">{{
          taskActive.title
        }}</h6>
      <div class="btn-group float-end">
        <button class="btn btn-outline-primary btn-sm" @click="showCommentNew = true" v-if="!showCommentNew"><i
          class="fa-duotone fa-comment"></i> {{ $t("tasks.comments.newComment") }}
        </button>
        <button class="btn btn-close" @click="showCommentNew = false" v-if="showCommentNew"></button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col">
          <Createcomment :task-id="taskId" @close="closeComment" v-if="showCommentNew"/>
        </div>
      </div>
      <div class="comments" v-if="taskActive &&  taskActive.history && Object.keys(taskActive.history).length > 0">
        <div class="row">
          <div class="col">
            <div class="list-group">
              <template v-for="(times, time) in taskActive.history">
                <template v-for="(comment, historyType) in times">
                  <div class="list-group-item" :key="comment.id" v-if="historyType === 'comment' && comment.comment">
                    <template v-if="( comment.id === setComment.id ) && editComment">
                      <Editor2 v-model="setComment.comment"></Editor2>
                      <div class="row">
                        <div class="col text-end">
                          <button @click="editComment = false; setComment = {};"
                                  class="btn btn-outline-secondary btn-sm me-3"><i
                            class="fa-duotone fa-x"></i> {{ $t("globals.cancel") }}
                          </button>
                          <button @click="updateComment" class="btn btn-outline-primary btn-sm me-3"><i
                            class="fa-duotone fa-paper-plane"></i> {{ $t("globals.send") }}
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="inlineCommentWrap">
                        <div class="infoLine w-100">
                          <template v-if="comment.crm_created_employee_id">
                            <small class="avatar"><img style="max-width: 40px"
                                                       :src="$filters.employeeIcon(getEmployees, comment.crm_created_employee_id, 'id')"
                                                       class="sketchAvatar"><b>
                              {{
                                $filters.employeeName(getEmployees, comment.crm_created_employee_id)
                              }}</b></small><i class="fa-duotone fa-comments ms-2"></i><small class="ms-2">{{
                              $filters.formatDateTime(comment.crm_created_date)
                            }}</small>
                          </template>
                          <template v-else>
                            <small class="avatar"><img style="max-width: 40px"
                                                       :src="$filters.generateSVGFromName(comment.author)"
                                                       class="sketchAvatar"><b class="color">{{ comment.author }}</b></small><i class="fa-duotone fa-comments"></i><small class="ms-2" >{{
                              $filters.formatDateTime(comment.crm_created_date)
                            }}</small>
                          </template>
                           <div class="btn-group ms-3" v-if="getUser.id === comment.crm_created_user_id">
                            <button class="btn btn-outline-primary btn-xs "
                                    :tooltip="this.$t('tasks.comments.editComment')" position="left"
                                    @click="setUpdateComment(comment)" style="margin-top: -3px;"><i
                              class="fa-duotone fa-edit"></i></button>

                          <button class="btn btn-outline-secondary btn-xs "
                                  :tooltip="this.$t('tasks.comments.deleteComment')" position="left"
                                  @click="deleteComment(comment.id)" style="margin-top: -3px;"><i
                            class="fa-duotone fa-trash"></i></button></div>
                        </div>
                        <div class="mb-1" v-html="$filters.renderLineBreaks(comment.comment)"></div>
                      </div>
                    </template>
                  </div>
                  <div class="list-group-item" v-else>
                    <div class="inlineCommentWrap">
                      <div class="infoLine w-100">
                        <template v-if="comment.crm_created_employee_id">
                          <small class="avatar me-2"><img style="max-width: 40px"
                                                     :src="$filters.employeeIcon(getEmployees, comment.crm_created_employee_id, 'id')"
                                                     class="sketchAvatar"><b>
                            {{ $filters.employeeName(getEmployees, comment.crm_created_employee_id) }}</b></small>
                        </template>
                        <template v-else>
                          <small class="avatar"><img style="max-width: 40px"
                                                     :src="$filters.generateSVGFromName(comment.author)"
                                                     class="sketchAvatar"><b class="color">
                            {{ comment.author }}</b></small>
                        </template>
                        <template v-if="comment.change_type === 'issue'"><i class="fa-duotone fa-edit"></i></template>
                        <template v-if="comment.change_type === 'customFile'"><i class="fa-duotone fa-paperclip"></i></template>
                        <template v-if="comment.change_type === 'time'"><i class="fa-duotone fa-stopwatch"></i></template>
                        <span class="inline-comment" v-html="$filters.renderLineBreaks(comment.comment)"></span><small>{{ $filters.formatDateTime(comment.crm_created_date)}}</small>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Createcomment from "@/routing/core/Tasks/Layouts/Createcomment.vue";
import {mapGetters} from "vuex";
import Editor2 from "@/components/Editor2.vue";
import axios from "axios";

export default {
  name: "Comments",
  props: {
    taskId: ""
  },
  emits: ["update"],
  computed: {
    ...mapGetters(["customerActive", "getEmployees", "modalsList", "taskActive", "getUser"])
  },
  components: {
    Editor2,
    Createcomment
  },
  data() {
    return {
      editComment: false,
      setComment: {},
      showCommentNew: false,
    }
  },
  methods: {
    setUpdateComment(comment) {
      this.setComment = comment;
      this.editComment = true;
    },
    closeComment() {
      this.showCommentNew = false;
    },
    deleteComment(commentId) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      this.showDropdown = false;
      this.$confirm(this.$t('tasks.comments.commentsTextDelete'), this.$t('tasks.comments.deleteComment'), 'warning', {
        confirmButtonText: this.$t('globals.yes'),
        cancelButtonText: this.$t('globals.no'),
        type: 'warning'
      }).then(() => {
        axios.delete(this.$store.state.apiUrl + "issue/" + this.taskId + "/comment/" + commentId, {headers: headers})
          .then(() => {
            this.editComment = false;
            this.$emit("update");
          })
          .catch((error) => {
            console.log(error);
          });
      }).catch((r) => {
        console.log(r);
      });
    },
    updateComment() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const hash = this.customerActive.hash;
      let postContent = {
        data: this.setComment,
        hash: hash
      }
      axios.put(this.$store.state.apiUrl + "issue/" + this.taskId + "/comment/" + this.setComment.id, postContent, {headers: headers})
        .then((response) => {
          this.editComment = false;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
}
</script>
<style scoped>
</style>
