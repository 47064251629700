<template>
  <div class="paginationWrapper mb-3">
    <ul class="pagination d-flex justify-content-center mt-3">
      <li class="page-item" :class="{disabled: ($store.getters[listName].offset === 0)}">
        <template v-if="$store.getters[listName].offset === 0">
          <button class="page-link disabled" href="#" aria-label="Previous">
            <span aria-hidden="true"><i class="fa-duotone fa-chevrons-left"></i></span>
          </button>
        </template>
        <template v-else>
          <button @click="$store.dispatch('setPage', {type:'previous', list:list, listName:listName, filterName:filterName})" class="page-link" href="#" aria-label="Previous">
            <span aria-hidden="true"><i class="fa-duotone fa-chevrons-left"></i></span>
          </button>
        </template>
      </li>


      <li class="page-item"
          :class="{disabled: (($store.getters[listName].offset + $store.getters[listName].limit) >= $store.getters[listName].complete || $store.getters[listName].complete < $store.getters[listName].limit)}">
        <template v-if="(($store.getters[listName].offset + $store.getters[listName].limit) >= $store.getters[listName].complete || $store.getters[listName].complete < $store.getters[listName].limit)">
          <button class="page-link" aria-label="Next">
            <span aria-hidden="true"><i class="fa-duotone fa-chevrons-right"></i></span>
          </button>
        </template>
        <template v-else>
          <button class="page-link" @click="$store.dispatch('setPage', {type:'next', list:list, listName:listName, filterName:filterName})" aria-label="Next">
            <span aria-hidden="true"><i class="fa-duotone fa-chevrons-right"></i></span>
          </button>
        </template>
      </li>
    </ul>
    <div class="overviewCounter float-end mb-3">
      <span v-if="$store.getters[listName].complete > 0">
  Einträge: {{ $store.getters[listName].offset + 1 }} -
  {{ Math.min($store.getters[listName].offset + $store.getters[listName].limit, $store.getters[listName].complete) }}
  von {{ $store.getters[listName].complete }}
</span>
      <span v-else>Keine Einträge</span>
    </div>
    <div class="limitBox float-start mb-3">
      <multiselect v-model="$store.state.lists[list].limit"     :options="listLimits" v-if="$store.state.lists[list].limit"
                   :multiple="false"
                   :canClear="false"
                   :closeOnSelect="true"
                   :placeholder="'Anzahl wählen'"
                   openDirection="top"
                   @update:model-value="$store.state.lists[list].offset = 0; $store.dispatch(filterName)"
                   class="form-select small">

      </multiselect>
<!--      <select  class="form-select  "  v-model="$store.state.lists[list].limit" @change="">-->
<!--        <option v-for="limit in listLimits">{{limit}}</option>-->
<!--      </select>-->

    </div>
  </div>
</template>
<script>
import Multiselect from '@vueform/multiselect'
  export default {
    name: "Pagination",

    // list = state.lists[list]
    // listName = getters[listName]
    // filterName = dispatch[filterName] -> Run search
    components: {
      Multiselect,
    },
    props: ["list", "listName", "filterName"],
    data() {
      return {
        listLimits: [5,10,15,20,50,100],
      }
    }
  }
</script>
