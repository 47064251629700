<template>
  <div class="draftLineWrap" :class="(draft.prepared && draft.prepared.typeString) ? draft.prepared.typeString : ''">
    <div class="lineitem first l-checkbox">
      <div class="checkbox">
        <input type="checkbox"
               :id="'id_'+draft.id"
               :value="draft.id"
               v-if="draftList && draftList.selected"
               v-model="draftList.selected[draft.id]"/>
        <label :for="'id_'+draft.id"></label>
      </div>
    </div>
    <div class="lineitem l-title" @click="setDraft(draft)">
      {{ draft.title }}
    </div>
    <div class="lineitem l-category" @click="setDraft(draft)">
      {{ draft.categoryTitle }}
    </div>
    <div class="lineitem l-attachments" >
      <template v-if="draft.attachment_id">{{draft.attachmentTitle}}</template>
    </div>
    <div class="lineitem last ml-auto">
      <template v-if="draftActive && draftActive.id === draft.id">
        <i class="fa-duotone fa-x fa-swap-opacity" @click="$store.commit('closeDraft');"></i>
      </template>
      <template v-else><i class="fa-duotone fa-chevrons-up"  @click="setDraft(draft)"></i></template>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import $ from "jquery";
import {useToast} from "vue-toastification";
import Editor2 from "@/components/Editor2.vue";

export default {
  name: "Headline",
  data() {
    return {
      modalContent: null,
      sendContent: false,
      sendContentTitle: '',
      sendContentText: '',
      orderConfirmationText: '',
      toast: useToast(),
      address: {},
      notice: {
        document_id: null,
        notice_message: ''
      },
      storno:{
        title: '',
        message:'',
        reason: ''
      }
    }
  },
  props: {
    draft: Object,
  },
  components: {
    Editor2
  },
  computed: {
    ...mapGetters(["draftActive", "getEmployees", "getConfigs", "draftList"])
  },
  methods: {
    setDraft(draft) {
      this.$store.dispatch("setActiveDraft", draft);
      this.$router.push({name: 'DraftDetails', params: {id: draft.id}})

    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  }

}
</script>
<style lang="scss">
span.iconAction {
  font-size: 1rem;
  &.disabled {
    display: none;
  }
  border: 0 !important
}
</style>
