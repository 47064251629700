<template>
  <div class="modal fade show " tabindex="-1"
       aria-hidden="true" style="display: block;">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $t("projects.newTask") }}</h5>
          <div class="btn-group" style="margin-left: auto;">
            <button type="button" class="btn btn-sm btn-outline-primary" @click="postTask">
              <i class="fa-duotone fa-floppy-disk"></i> {{ $t("globals.save") }}
            </button>

          </div>
          <button type="button" class="btn-close ms-3" @click="closeTask"></button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="projectTitle">{{ $t("globals.title") }}</label>
            <input type="text" class="form-control" id="projectTitle" v-model="newTaskItem.title">
          </div>
          <div class="form-group">
            <label for="projectDesc">{{ $t("globals.description") }}</label>
            <Editor2 v-model="newTaskItem.description"></Editor2>
          </div>
          <div class="row">
            <div class="col-4">
              <label>{{ $t("tasks.create.start") }}</label>
              <VueDatePicker class="small" v-model="newTaskItem.start_date" model-type="yyyy-MM-dd" format="dd.MM.yyyy"
                             :placeholder="this.$t('tasks.create.startDate')" :auto-apply="true"
                             :close-on-auto-apply="true" locale="de"
                             :enable-time-picker="false" cancel-text="X" :select-text="this.$t('globals.choose')"
                             :teleport="true"></VueDatePicker>
            </div>
            <div class="col-4">
              <label>{{ $t("globals.submission") }}</label>
              <VueDatePicker class="small" v-model="newTaskItem.due_date" model-type="yyyy-MM-dd" format="dd.MM.yyyy"
                             :placeholder="this.$t('tasks.create.submissionDate')" :auto-apply="true"
                             :close-on-auto-apply="true" locale="de"
                             :enable-time-picker="false" cancel-text="X" select-text="this.$t('globals.choose')"
                             :teleport="true"></VueDatePicker>
            </div>
            <div class="col-4">
              <label>{{ $t("globals.processing") }}</label>
              <VueMultiselect class="small" :options="getEmployees" v-model="newTaskItem.employee_id" :multiple="false"
                                valueProp="id"
                              modelValue="id" :valueProp="'id'" :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                              track-by="e_name" :isOpen="true"
                              :placeholder="this.$t('globals.editor')" label="e_name" :track-by="'id'"
                              :allow-empty="false"
                              :select-label="''" :taggable="false"
              ></VueMultiselect>

            </div>
          </div>
          <div class="row" v-if="!customerActive || !customerActive.hash">
            <div class="col-6">
              <label>{{ $t("globals.customer") }}</label>
              <customersearch v-model="newTaskItem.customer" @update="getProjects" :small="true"/>
            </div>
            <div class="col-6" v-if="projects.length">
              <label>{{ $t("globals.project") }}</label>
              <VueMultiselect class="small" :options="projects" v-model="newTaskItem.project" :multiple="false"
                              :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                              :placeholder="this.$t('globals.project')" label="title" :track-by="'id'"
                              :allow-empty="false"
                              :select-label="''" :hide-selected="true"
                              :show-no-results="true" :options-limit="300"
                              :limit="1" :disabled="false" :taggable="false"
              ></VueMultiselect>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-6">
              <label style="display: block;">{{ $t("globals.customer") }}</label>
              <strong>
                <template v-if="customerActive.c_company">
                  {{ customerActive.c_company }}
                  <span class="text-xs customerInfo"
                        v-if="customerActive.c_title || customerActive.c_firstname || customerActive.c_lastname">
          <template v-if="customerActive.c_title">{{ customerActive.c_title }}&nbsp;</template>
          <template v-if="customerActive.c_firstname">{{ customerActive.c_firstname }}&nbsp;</template>
          <template v-if="customerActive.c_lastname">{{ customerActive.c_lastname }}</template>
        </span>
                </template>
                <template v-else>
                  <template v-if="customerActive.c_title">{{ customerActive.c_title }}&nbsp;</template>
                  <template v-if="customerActive.c_firstname">{{ customerActive.c_firstname }}&nbsp;</template>
                  <template v-if="customerActive.c_lastname">{{ customerActive.c_lastname }}</template>
                </template>
              </strong>
            </div>
            <div class="col-6" v-if="projects.length">
              <label>{{ $t("globals.project") }}</label>
              <VueMultiselect class="small" :options="projects" v-model="newTaskItem.project" :multiple="false"
                              :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                              :placeholder="this.$t('projects.chooseProject')" label="title" :track-by="'id'"
                              :select-label="this.$t('projects.chooseProject')" :taggable="false"
              ></VueMultiselect>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12 col-lg-4">
              <label class="color">{{$t("globals.priority")}}</label>
              <VueMultiselect class="small" :options="Object.values(getConfigs.issue_prios)"
                              v-model="newTaskItem.priority_id"
                              :multiple="false" :valueProp="'id'" ref="multiselect_prio"
                              :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                              :isOpen="true" track-by="title" openDirection="bottom"
                              :placeholder="this.$t('projects.choosePriority')" label="title" :allow-empty="false"
                              :select-label="this.$t('projects.choosePriority')"
              ></VueMultiselect>


            </div>
            <div class="col-12 col-lg-4">
              <label class="color">{{$t("globals.tracker")}}</label>
              <VueMultiselect class="small" :options="Object.values(getConfigs.issue_trackers)"
                              v-model="newTaskItem.tracker_id"
                              :multiple="false" :valueProp="'id'" ref="multiselect_tracker"
                              :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                              :isOpen="true" track-by="title" openDirection="bottom"
                              :placeholder="this.$t('tasks.details.chooseTracker')" label="title" :allow-empty="false"
                              :select-label="this.$t('tasks.details.chooseTracker')"
              ></VueMultiselect>
            </div>

            <div class="col-12 col-lg-4">
              <label class="color">Geschätzter Zeitaufwand</label>
              <input type="number" class="form-control small" v-model="newTaskItem.estimated_hours" step="0.25"/>
            </div>
          </div>
          <div v-if="getMenu && getMenu.events" class="row">
            <div class="columns xlarge-12 large-12 medium-12 small-12">
              <div class="form-group">
                <h6>{{ $t("sketch.eventAccess") }}</h6>
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="products-read-none" autocomplete="off"
                         v-model="newTaskItem.fillForDates">
                  <label class="btn btn-outline-primary" for="products-read-none">{{ $t("globals.no") }}</label>
                  <input type="radio" value="1" class="btn-check" id="products-read-yes" autocomplete="off"
                         v-model="newTaskItem.fillForDates">
                  <label class="btn btn-outline-primary" for="products-read-yes">{{ $t("globals.yes") }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Editor2 from "@/components/Editor2.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import axios from "axios";
import {mapGetters} from "vuex";
import VueMultiselect from "vue-multiselect";
import customersearch from "@/filter/components/customersearch.vue";

export default {
  name: "Create",
  computed: {
    // ...mapGetters(["customerActive", "stateFilter", "projectFilter", "getEmployees"]),
    ...mapGetters(["customerActive", "getEmployees", "getMenu", "getConfigs", "getUser"]),
  },
  components: {customersearch, Editor2, VueDatePicker, VueMultiselect},
  data() {
    return {
      newTaskItem: {
        title: "",
        description: "",
        start_date: new Date().toISOString().substr(0, 10),
        due_date: "",
        project: {},
        task_id: '',
        priority_id: {
          id: '',
          title: ''
        },
        tracker_id: {
          id: '',
          title: ''
        },
        estimated_hours: '',
        employee_id: {
          id: '',
          e_name: ''

        },
        fillForDates: 0,
        customer: {
          id: '',
          hash: '',
          full_name: ''
        }
      },
      projects: []
    };
  },
  methods: {
    prefillNewTask(){
      if(this.getUser){
        this.newTaskItem.employee_id.id = this.getUser._isEmployee;
        this.newTaskItem.employee_id.e_name = this.getUser.employee.e_name;
      }
      this.newTaskItem.priority_id.id = '12';
      this.newTaskItem.priority_id.title = 'Normal';
      this.newTaskItem.tracker_id.id = '4';
      this.newTaskItem.tracker_id.title = 'Leistung (ohne Angebot)';
    },
    postTask() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let hash;
      if (this.customerActive && this.customerActive.hash) {
        hash = this.customerActive.hash;
      } else {
        hash = this.newTaskItem.customer.hash;
      }
      delete this.newTaskItem.customer;
      if (this.newTaskItem.employee_id) {
        this.newTaskItem.employee_id = this.newTaskItem.employee_id.id;
      }
      this.correctId('priority_id');
      this.correctId('tracker_id');
      let postContent = {
        hash: hash,
        data: this.newTaskItem,
      }
      postContent.data.project_id = this.newTaskItem.project.id;
      // if (this.stateFilter && this.stateFilter.value) {
      //   postContent.data.state_id = this.stateFilter.value;
      // } else {
      postContent.data.state_id = 5;
      // }
      axios.post(this.$store.state.apiUrl + "issue/", postContent, {headers}).then((r) => {
        this.closeTask();
        this.$store.dispatch("tasksFilter");
        //this.$router.push({name: "tasks", params: {id: r.data.result.data.id}});
      }).catch((r) => {
        this.$store.dispatch("handleApiResponse", r.response);
      });
    },
    correctId(key) {
      this.newTaskItem[key] = this.newTaskItem[key].id;
    },
    closeTask() {
      this.$emit('close-task');
      this.$store.commit("closeModal", "task");
    },
    getProjects() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if(this.customerActive.id){
      axios.get(this.$store.state.apiUrl + "project/search?filter_customer=" + this.customerActive.id + "&filter_state=active&limit=0", {headers}).then((r) => {
        this.projects = r.data.result.data;
      });
      }
    }
  },
  created() {
    if (this.taskActive) {
      this.newTaskItem = this.taskActive;
    }
    this.prefillNewTask();
  },
  mounted() {
    if (this.customerActive) {
      this.getProjects();
    }
  },
  watch: {
    newTaskItem: {
      handler: function (val, oldVal) {
        if (val.customer && val.customer.id) {
          this.getProjects();
        }
      },
      deep: true
    }
  }
}
</script>
<style scoped>
</style>
