<template>
  <div class="row" v-if="getMenu && getMenu.accounting">
    <div class="col-xl-3 col-lg-6 col-12">
      <small-card v-if="stats.currentOrders.title"
                  :title="stats.currentOrders.title"
                  :value="stats.currentOrders.value"
                  :percentage="stats.currentOrders.percentage"
                  :percentageColor="stats.currentOrders.percentageColor"
                  :iconClass="stats.currentOrders.iconClass"
                  :iconBackground="stats.currentOrders.iconBackground"
                  :detail="stats.currentOrders.detail"
                  directionReverse
      ></small-card>
    </div>
    <div class="col-xl-3 col-lg-6 col-12">
      <small-card v-if="stats.currentOffers.title"
                  :title="stats.currentOffers.title"
                  :value="stats.currentOffers.value"
                  :percentage="stats.currentOffers.percentage"
                  :percentageColor="stats.currentOffers.percentageColor"
                  :iconClass="stats.currentOffers.iconClass"
                  :iconBackground="stats.currentOffers.iconBackground"
                  :detail="stats.currentOffers.detail"
                  directionReverse
      ></small-card>
    </div>
    <div class="col-xl-3 col-lg-6 col-12">
      <small-card v-if="stats.currentMoneyIn.title"
                  :title="stats.currentMoneyIn.title"
                  :value="stats.currentMoneyIn.value"
                  :percentage="stats.currentMoneyIn.percentage"
                  :percentageColor="stats.currentMoneyIn.percentageColor"
                  :iconClass="stats.currentMoneyIn.iconClass"
                  :iconBackground="stats.currentMoneyIn.iconBackground"
                  :detail="stats.currentMoneyIn.detail"
                  directionReverse
      ></small-card>
    </div>
    <div class="col-xl-3 col-lg-6 col-12">
      <small-card v-if="stats.currentMoneyOut.title"
                  :title="stats.currentMoneyOut.title"
                  :value="stats.currentMoneyOut.value"
                  :percentage="stats.currentMoneyOut.percentage"
                  :percentageColor="stats.currentMoneyOut.percentageColor"
                  :iconClass="stats.currentMoneyOut.iconClass"
                  :iconBackground="stats.currentMoneyOut.iconBackground"
                  :detail="stats.currentMoneyOut.detail"
                  directionReverse
      ></small-card>
    </div>
  </div>
</template>
<script>
import SmallCard from "@/routing/core/Dashboard/statistic/SmallCard.vue";
import axios from "axios";
import {mapGetters} from "vuex";
export default {
  components: {
    SmallCard
  },
  name: 'documents',
  computed: {
    ...mapGetters(["getMenu"]),

  },
  props:{
    options: {
      type: Object,
      default: () => {
        return {
          type: "orders",
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate(),
        }
      }
    },
    filter: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      chartData: {},
      stats: {
        currentOffers: {
          title: "",
          value: "",
          percentage: "",
          iconClass: "BIconBatteryFull",
          detail: "",
          iconBackground: "bg-gradient-primary",
        },
        currentOrders: {
          title: "",
          value: "",
          percentage: "",
          iconClass: "BIconArrow90degDown",
          iconBackground: "bg-gradient-danger",
          detail: "",
        },
        currentMoneyOut: {
          title: "",
          value: "",
          percentage: "",
          iconClass: "BIconBookmark",
          percentageColor: "text-danger",
          iconBackground: "bg-gradient-success",
          detail: "",
        },
        currentMoneyIn: {
          title: "",
          value: "",
          percentage: "",
          iconClass: "BIconBookmark",
          percentageColor: "text-warning",
          iconBackground: "bg-gradient-warning",
          detail: "",
        },
      },
    }
  },
  methods: {
    getStatsDocumentOrders() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/documents/currentOrders" + this.filter, {headers}).then((r) => {
        if (r.data.result && r.data.result.counter) {
          VueInner.stats.currentOrders.title = r.data.result.data.items + " " + this.$t("dashboard.openOrders");
          VueInner.stats.currentOrders.value = VueInner.$filters.priceEuro(r.data.result.data.brutto.open)
          VueInner.stats.currentOrders.percentage = VueInner.$filters.priceEuro(r.data.result.data.brutto.complete);
          VueInner.stats.currentOrders.detail = this.$t("dashboard.totalVolume");
        }
        VueInner.loading = false;
      });
    },
    getStatsDocumentOffers() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/documents/currentOffers" + this.filter, {headers}).then((r) => {
        if (r.data.result && r.data.result.counter) {
          VueInner.stats.currentOffers.title = r.data.result.data.items + " " + this.$t("dashboard.openOffers");
          VueInner.stats.currentOffers.value = VueInner.$filters.priceEuro(r.data.result.data.brutto.complete)
          VueInner.stats.currentOffers.percentage = VueInner.$filters.priceEuro(r.data.result.data.brutto.highest);
          VueInner.stats.currentOffers.detail = this.$t("dashboard.highestOffer");
        }
        VueInner.loading = false;
      });
    },
    getStatsInvoices() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/invoices/summary" + this.filter, {headers}).then((r) => {
        if (r.data.result && r.data.result.counter) {
          VueInner.stats.currentMoneyIn.title = r.data.result.data.in.items + " " + this.$t("dashboard.openOutgoingInvoices");
          VueInner.stats.currentMoneyIn.value = VueInner.$filters.priceEuro(r.data.result.data.in.complete)
          VueInner.stats.currentMoneyIn.percentage = VueInner.$filters.priceEuro(r.data.result.data.in.thisYear);
          VueInner.stats.currentMoneyIn.detail = this.$t("dashboard.fromCurrentYear");
          VueInner.stats.currentMoneyOut.title = r.data.result.data.out.items + " " + this.$t("dashboard.openIncomingInvoices");
          VueInner.stats.currentMoneyOut.value = VueInner.$filters.priceEuro(r.data.result.data.out.complete)
          VueInner.stats.currentMoneyOut.percentage = VueInner.$filters.priceEuro(r.data.result.data.out.thisYear);
          VueInner.stats.currentMoneyOut.detail = this.$t("dashboard.fromCurrentYear");
        }
        VueInner.loading = false;
      });
    },

  },
  created() {
    if (this.getMenu && this.getMenu.accounting) {
      this.getStatsDocumentOffers();
      this.getStatsDocumentOrders();
      this.getStatsInvoices();
    }
  },
  watch: {
    filter: function (newVal, oldVal) {
      if (this.getMenu && this.getMenu.accounting) {
        this.getStatsDocumentOffers();
        this.getStatsDocumentOrders();
        this.getStatsInvoices();
      }
    }
  }
}
</script>
