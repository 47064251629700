<template>

  <template v-if="$route && $route.matched && $route.matched.length">



    <template v-if="$route.matched[0].name === 'customers'">


      <button class="btn btn-outline-primary btn-sm me-3" :tooltip="'E-Mail senden'" position="left"
              @click="$store.commit('openModal', 'mail')"><i class="fa-duotone  fa-mail-bulk"></i></button>



    </template>
    <template v-if="$route.matched[0].name === 'employees'">


      <button class="btn btn-outline-primary btn-sm me-3" :tooltip="'E-Mail senden'" position="left"
              @click="$store.commit('openModal', 'mail')"><i class="fa-duotone  fa-mail-bulk"></i></button>



    </template>
  </template>
</template>
  <script>

    import {mapGetters} from "vuex";
    import Mail from "@/components/Mail.vue";

    export default {
      name: "mailtoSelected",
      data() {
        return {
          batchMailWindow: false
        };
      },
      computed:{
        ...mapGetters(['documentList']),

      },
      components: {Mail},

      methods: {

      }
    }
  </script>
  <style scoped>
  </style>
