<template>
  <div class="card">
    <div class="card-body" v-if="mails && mails.length > 0">
      <div class="mail-item" v-for="mail in mails" v-bind:key="mail.id">
        <div class="row mb-3 pb-3 borderRow">
          <!--{{ mail }}-->

          <div class="col-9 justify-content-center align-self-center">
            <p class="mb-0 text-sm font-weight-bold"><img style="max-width: 40px"
                                                          :src="$filters.employeeIcon(getEmployees, mail.employee_created, 'u_id')"
                                                          class="sketchAvatar"> {{ $filters.formatDateTime(mail.created_time) }}</p>
            <p class="mb-0 text-xs">{{ mail.payload.subject }}</p>

          </div>
          <div class="col-3 text-end  justify-content-center align-self-center">


            <!-- if attachments lenght print icon -->
            <span v-if="mail.payload.attachments.length > 0" style="margin-right: 6px;">
              <i class="fa-duotone fa-paperclip"></i> {{ mail.payload.attachments.length }}
            </span>

            <button type="button" class="btn btn-outline-primary btn-xs" @click="setMail(mail);" :tooltip="this.$t('globals.view')" :position="'left'"><i
              class="fa-duotone fa-eye"></i>
            </button>
          </div>
        </div>
        <Modal v-if="activeMail">
          <div class="modal  show" style="display: block;">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">{{ activeMail.payload.subject }}</h5>
                  <button type="button" class="btn-close" @click="resetMail" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div v-html="activeMail.payload.body"></div>
                  <div class="attachments" v-if="activeMail.payload.attachments.length">
                    <h5> <i class="fa-duotone fa-paperclip"></i> {{$t("globals.attachments")}}</h5>
                    <ul>
                      <li v-for="(att, inn) in activeMail.payload.attachments">
                        <button class="btn" @click="openAttachmentFromMail(activeMail.id, inn)"><i class="fa-duotone fa-paperclip"></i>{{$t("globals.data")}} {{inn+1}}</button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-outline-secondary" @click="resetMail"><i class="fa-duotone fa-xmark-circle"></i>{{$t("globals.close")}}</button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
    <div class="card-body" v-else><p class="noItemsFound">{{$t("activity.noEntriesFound")}}</p></div>
  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "CustomerMails",
  props: {
    customerId: {}
  },
  data() {
    return {
      mails: [],
      activeMail: null,
    };
  },
  computed: {
    ...mapGetters(["getPlugins", "getEmployees", "customerActive"])
  },
  methods: {
    openAttachmentFromMail(mailId, key) {

      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.customerActive.id + "/mails/" + mailId + "/attachment/" + key, {headers}).then((r) => {

        const file = r.data.result.data;
        const linkSource = 'data:'+file.mimeType+';base64,' + file.file;

        // download fileName
        // trigger download
        var a = window.document.createElement('a');
        a.setAttribute('href', linkSource);
        a.setAttribute('download', file.filename);
        window.document.body.appendChild(a);
        a.click();
      });

    },
    getMails() {
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.customerActive.id + "/mails", {headers}).then((r) => {
        this.mails = r.data.result.data;
        this.loading = false;
      });
    },
    setMail(mail) {
      this.activeMail = mail;
    },
    resetMail() {
      this.activeMail = null;
    },
  },
  created() {
    this.getMails();
  },
  watch: {
    customerId: function () {
      this.getMails();
    }
  }
};
</script>
<style lang="scss">
.modal {
  z-index: 10000 !important;
}

.modal-backdrop {
  z-index: 9999 !important;
}
</style>
