<template>
  <template v-if="$route && $route.matched && $route.matched.length">
    <template v-if="$route.matched[0].name === 'customers'">
      <button class="btn btn-outline-primary btn-sm  me-3" :tooltip="$t('merge.customers')" position="left"
              v-if="shouldShowMergeButton"
              @click="showMergeDialog = true"><i class="fa-duotone fa-merge"></i></button>
      <modal v-if="showMergeDialog">
        <div class="modal show" style="display: block;">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header"> Kunden zusammenfassen
                <button class="btn-close" @click="showMergeDialog = false"></button>
              </div>
              <div class="modal-body">
                <p>Wollen Sie die ausgewählten Kunden wirklich zusammenführen?</p>
                <p>Bitte wählen Sie einen Hauptkontakt. In diesen Kontakt werden die Daten aus dem weiteren Kontakt
                  übernommen.</p>
                <ul style="list-style: none; margin-left: 0; padding-left: 0;">
                  <template v-for="(selected, id) in customerList.selected">
                    <li class="mb-2" v-if="selected">
                      <button class="btn btn-sm me-3"
                              :class="(mainContact === id) ? 'btn-primary':'btn-outline-primary'"
                              @click="mainContact = id"><i class="fa-duotone fa-star"></i>Hauptkontakt
                      </button><template v-if="getCustomerById(id).c_company"><strong>{{ getCustomerById(id).c_company }}</strong> | </template>{{ getCustomerById(id).c_firstname }} {{ getCustomerById(id).c_lastname }}

                       <span
                        class="text-xs float-end">Erstellt: {{ $filters.formatDate(getCustomerById(id).crm_created_date) }}</span>

                    </li>
                  </template>
                </ul>
                <button class="btn btn-outline-primary btn-sm  me-3"
                        :disabled="mainContact === 0"
                        @click="mergeSelected"><i class="fa-duotone fa-merge"></i> Kunden zusammenführen
                </button>
              </div>
            </div>
          </div>
        </div>
      </modal>
    </template>
  </template>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "mergeSelected",
  data() {
    return {
      showMergeDialog: false,
      mainContact: 0
    };
  },
  computed: {
    // Check if filter customer selected array has exactly 2 entries
    // If yes, show merge button
    ...mapGetters(['customerList']),
    shouldShowMergeButton() {
      const selectedIds = Object.keys(this.customerList.selected);
      const trueCount = selectedIds.filter(id => this.customerList.selected[id]).length;
      return trueCount >= 2;
    },
    getCustomerById() {
      return id => this.customerList.data.find(customer => customer.id === id) || {};
    }
  },
  components: {},
  methods: {
    mergeSelected(state, listName) {
      // get selected items
      const list = state.lists[listName];
      const selected = list.selected;
      const data = {
        mainContact: this.mainContact,
        mergeContacts: selected
      }
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      //const headers = {};
      axios.post(state.apiUrl + "batch/" + listName + "/merge", data, {headers}).then((e) => {
        console.log(e);
      })
    }
  }
}
</script>
<style scoped>
</style>
