<template>
  <div class="row" v-if="getMenu && getMenu.customers">
    <div class="col-lg-3 mb-lg">
      <div class="card z-index-2">
        <BarChart v-if="chartDataCustomers.labels" :chartData="chartDataCustomers" class="boxWhite"
                  :options='barHorizontalOptions'/>
      </div>
    </div>
    <div class="col-lg-3 mb-lg">
      <div class="card z-index-2">
        <BarChart v-if="chartDataCustomersLeads.labels" :chartData="chartDataCustomersLeads" class="boxWhite"
                  :options='barHorizontalOptions'/>
      </div>
    </div>
    <div class="col-lg-3 mb-lg">
      <div class="card z-index-2">
        <BarChart v-if="chartDataCustomersOffers.labels" :chartData="chartDataCustomersOffers" class="boxWhite"
                  :options='barHorizontalOptions'/>
      </div>
    </div>
    <div class="col-lg-3 mb-lg">
      <div class="card z-index-2">
        <BarChart v-if="chartDataCustomersOrders.labels" :chartData="chartDataCustomersOrders" class="boxWhite"
                  :options='barHorizontalOptions'/>
      </div>
    </div>
  </div>
</template>
<script >
import {BarChart} from "vue-chart-3";
import axios from "axios";
import {mapGetters} from "vuex";
export default {
  name: 'acquisition',
  components: {
    BarChart
  },
  props: {
    filter: {
      type: String
    }
  },
  data() {
    return {
      chartDataCustomers: {},
      chartDataCustomersLeads: {},
      chartDataCustomersOffers: {},
      chartDataCustomersOrders: {},
      barHorizontalOptions: {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
          },
        },
        scales: {
          y: {
            grid: {
              drawBorder: true,
              display: true,
              drawOnChartArea: true,
              drawTicks: true,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#ccc",
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
          x: {
            grid: {
              drawBorder: true,
              display: false,
              drawOnChartArea: true,
              drawTicks: true,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              color: "#ccc",
              padding: 20,
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
        },
      },
    }
  },
  methods: {
    getStatsCustomersYear() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/customers/year" + this.filter, {headers}).then((r) => {
        if (r.data.result && r.data.result.counter) {
          VueInner.chartDataCustomersLabels = r.data.result.data;
          VueInner.chartDataCustomers = r.data.result.data.contacts;
          VueInner.chartDataCustomersLeads = r.data.result.data.leads;
          VueInner.chartDataCustomersOffers = r.data.result.data.offers;
          VueInner.chartDataCustomersOrders = r.data.result.data.orders;
        }
        VueInner.loading = false;
      });
    }
  },
  created(){
    if (this.getMenu && this.getMenu.customers) {
      this.getStatsCustomersYear();
    }
  },
  computed: {
    ...mapGetters(["getMenu", "getConfigs"])
  },
  watch: {
    filter: function (newVal, oldVal) {
      if (this.getMenu && this.getMenu.customers) {
        this.getStatsCustomersYear();
      }
    }
  }

}

</script>
