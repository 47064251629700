<template>

    <div class="appView tasks purpleView">
      <Details v-if="$route.params.id"/>
      <List />
    </div>


</template>
<script>
import List from "./List.vue";
import Details from "@/routing/core/Tasks/Details.vue";
export default {
  name: 'TasksOverview',
  data() {
    return {
      tasks: [],
    }
  },
  components: {
    Details,
    List
  },

}
</script>
