<template>
  <div class="productItem activeProduct">
    <div class="card">
      <div class="card-header ">
         <h6 class="inline"><i class="fa-duotone fa-square-info"></i> {{$t("cutomerTitle.details.configTitle")}}</h6>
        <button type="button" class="btn btn-close float-end ms-4"
                @click="$store.commit('closeConfigCustomerTitle');$store.commit('closeModal', 'ConfigCustomerTitle')">
        </button>
          <button type="button" class="btn btn-outline-primary btn-sm me-4 float-end ms-3" @click="updateConfig()"><i
            class="fa-duotone fa-floppy-disk"></i> {{$t("globals.save")}}
          </button>

          <button type="button" class="btn btn-outline-secondary btn-sm float-end " v-if="d.id" @click="deleteConfig()"><i
            class="fa-duotone fa-trash"></i> {{$t("globals.delete")}}
          </button>
      </div>



      <div class="card-body">

        <div class="row">

          <div class="col-3">
            <div class="form-group">
              <label>{{$t("globals.name")}}</label>
              <input class="form-control" type="text" v-model="d.name" >
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label> {{$t("globals.greeting")}}</label>
              <input class="form-control" type="text" v-model="d.greeting" >
            </div>
          </div>

          <div class="col-3">
            <div class="form-group">
              <label>{{$t("globals.ordering")}}</label>
              <input class="form-control" type="button" v-model="d.ordering" >
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>



</template>
<script>
import axios from "axios";
import Editor2 from "@/components/Editor2";
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Config/Basic/CustomerTitle/Layouts/Headline.vue";
import $ from "jquery";

export default {
  name: "CustomerTitleDetails",
  components: {
    Headline,
    Editor2
  },
  computed: {
    ...mapGetters(["getEmployees", "getConfigCustomerTitle", "getConfigs"])
  },
  data() {
    return {
      d: {},
      isNew: true
    };
  },
  methods: {
    updateConfig() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if (VueInner.d.id !== undefined) {
        axios.put(this.$store.state.apiUrl + "config/customerTitle/" + VueInner.d.id, VueInner.d, {headers}).then(() => {

          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Anrede wurde erfolgreich gespeichert');
          // Load Products
          this.$store.dispatch("loadConfig", {type: "customerTitleConfigs", percent: 75, message: 'Der Downloader wird heruntergeladen'}).then(() => {
            VueInner.$store.dispatch('toastSuccess', 'Anrede wurde erfolgreich gespeichert');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.commit('closeConfigCustomerTitle');
          });
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      } else {
        axios.post(this.$store.state.apiUrl + "config/customerTitle/", VueInner.d, {headers}).then(() => {

          this.$store.commit('closeModal', 'ConfigCustomerTitle');
          this.$router.push({name: 'ConfigCustomerTitle'})
          // dispatch toastSuccess
          this.$store.dispatch("loadConfig", {type: "customerTitleConfigs", percent: 75, message: 'Der Downloader wird heruntergeladen'}).then(() => {
            VueInner.$store.commit('closeModal', 'ConfigCustomerTitle');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.dispatch('toastSuccess', 'Anrede wurde erfolgreich gespeichert');
            VueInner.$store.commit('closeConfigCustomerTitle');
          });
          // Load Products
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }
    },
    deleteConfig() {
      let VueInner = this;
      this.$confirm('Soll die Anrede wirklich gelöscht werden?', 'Anrede löschen', "question", {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        confirmButtonColor: '#6f42c1',
        type: 'warning'
      }).then(() => {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + "config/customerTitle/" + VueInner.d.id, {headers}).then(() => {
          // close
          VueInner.$store.commit('closeConfigCustomerTitle');
          // dispatch toastSuccess
          this.$store.dispatch("loadConfig",{type: "customerTitleConfigs", percent: 75, message: 'Der Downloader wird heruntergeladen'}).then(() => {
            VueInner.$store.dispatch('toastSuccess', 'Anrede wurde erfolgreich gelöscht');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.commit('closeConfigCustomerTitle');

          });
          // Load Products
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }).catch((r) => {
        console.log(r);
      });
    },
    loadDraft() {
      if (this.$route.params.id) {
        this.d = this.getConfigCustomerTitle

        this.isNew = false;
      } else {
        this.isNew = true;
      }
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  created() {
    this.loadDraft();
  },
  watch: {
    $route(to, from) {
      this.loadDraft();
    }
  }
};
</script>
<style scoped>
h6.inline{
  display: inline-block;
  margin-top: 6px;
  margin-bottom: 6px;
}
</style>
