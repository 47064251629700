// drafts.js

export const state = {
  modals: {
    customer: false,
    address: false,
    document: false,
    project: false,
    task: false,
    mail: false,
    event: false,
    legal: false,
    sepa: false,
    product: false,
    previewFile: false,
    ConfigAccountingKeys: false,
    ConfigBankAccounts: false,
    ConfigCustomerTaxes: false,
    ConfigCustomerAcquisitions: false,
    ConfigCustomerState: false,
    ConfigCustomerPrio: false,
    ConfigCustomerLead: false,
    ConfigCustomerStructure: false,
    ConfigCustomerType: false,
    ConfigCustomerTags: false,
    ConfigCountryConfigs: false,
    ConfigCustomerTitle: false,
    ConfigTimeConfigs: false,
    ConfigWorkflowStates: false,
    ConfigWorkflowActivities: false,
    ConfigWorkflowTracker: false,
    ConfigWorkflowPrio: false,
    ConfigLangOverride: false,
    ConfigYearplan: false,
    ConfigEmployeeCategory: false,
    import: false,
    export: false,
    widget: false,
  },
}

export const mutations = {
  closeModal(state, payload) {
    state.modals[payload] = false;
  },
  openModal(state, payload) {
    state.modals[payload] = true;
  },

}

export const actions = {
  // Aktionen für drafts
}

export const getters = {
  modalsList: state => {
    return state.modals;
  },
}
