<template>
  <input type="text" class="form-control small" v-model="$store.state.filters[type][filter.name].value" @keyup.enter="$store.dispatch(type+'Filter');" :placeholder="filter.name" />
</template>
<script>


import {mapGetters} from 'vuex';
export default {
  name: "textinput",
  data() {
    return {
      customers: "",
      options: [],

    };
  },
  props: {
    type: {
      type: String,
      required: true
    },
    filter: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(["getConfigs"]),
  },
  created() {
    this.options = this.getConfigs[this.filter.source];
  }
}
</script>
<style scoped>
</style>
