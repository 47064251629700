<template>
  <div class="card-body draft-list">
    <p class="text-sm text-secondary" v-if="!$store.state.languageOverrides.items || $store.state.languageOverrides.items.length === 0">Es wurden keine Sprachoverrides gefunden.</p>
    <Sort @sort="resortOrder($event)"/>
    <div class="draft-item" v-for="draft in getLanguageOverrides.items"
         :key="draft.id">
      <Headline :draft="draft"/>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Config/Basic/LangOverrides/Layouts/Headline.vue";
import Sort from "@/routing/core/Config/Basic/LangOverrides/Layouts/Sort.vue";
import axios from "axios";
import $ from "jquery";

export default {
  name: "LangOverridesList",
  computed: {
    ...mapGetters(["getEmployees", "draftList", "getConfigs",  'getLanguageOverrides'])
  },
  data() {
    return {};
  },
  components: {
    Sort,
    Headline,
  },
  methods: {
    resortOrder(payload) {
    },

    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  created() {
  },
  watch: {
    $route(to, from) {
    },
    draftActive() {
    }
  }
};
</script>
