<template>
  <div class=" event-list"   >

    <perfect-scrollbar ref="scroll">

      <p class="text-sm text-secondary" v-if="!events || events.length === 0">Es wurden keine Unterveranstaltungen
        gefunden.</p>
      <div class="eventWrap" v-for="event in events"
           :key="event.id">
        <Headline :event="event"/>
      </div>
    </perfect-scrollbar>

  </div>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";
import $ from "jquery";
import Headline from "@/routing/core/Events/Layouts/Headline.vue";

export default {
  name: "TasksList",
  props: {
    events: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(["getEmployees", "eventList" ])
  },

  data() {

    return {
      t: {
        get() {
          return this.events;
        },
        set(value) {
          this.events = value;
        }
      },


      event:{},
      today: new Date().toISOString().slice(0, 10),
    };
  },
  components: {
    Headline,
  },
  methods: {
    filter() {
      //let VueInner = this;
    },


    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  created() {
  },
  watch: {
    // watch router params id
    // taskActive() {
    //   if (this.taskActive && this.taskActive.id) {
    //     this.activeTask = this.taskActive.id;
    //   } else {
    //     this.activeTask = null;
    //     //this.$store.dispatch("setActiveTask", null);
    //   }
    // },

  }
};
</script>
