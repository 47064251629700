<template>
  <template v-for="field in form">
    <template v-if="field.type === 'text'">
      <div class="form-group">
      <label v-if="field.params.showlabel">{{field.title}}</label>

      <input class="form-control" type="text" v-model="$store.state.activeItems.customer.customFields[groupId].fields[field.name].field_value" />
      </div>
    </template>
    <template v-if="field.type === 'integer'">
      <div class="form-group">
      <label v-if="field.params.showlabel">{{field.title}}</label>
        <input class="form-control" type="number"  v-model="$store.state.activeItems.customer.customFields[groupId].fields[field.name].field_value" /></div>
    </template>
    <template v-if="field.type === 'list'">
      <div class="form-group">
      <label v-if="field.params.showlabel">{{field.title}}</label>
      <select class="form-select" v-model="$store.state.activeItems.customer.customFields[groupId].fields[field.name].field_value" >
        <option :value="option.value" v-for="option in field.fieldparams.options" :key="option.value">{{option.name}}</option>
      </select>
      </div>
    </template>
    <template v-if="field.type === 'textarea'">
      <div class="form-group">
      <label v-if="field.params.showlabel">{{field.title}}</label>
      <textarea class="form-control" type="textarea"  v-model="$store.state.activeItems.customer.customFields[groupId].fields[field.name].field_value" />
      </div>
    </template>
    <template v-if="field.type === 'calendar'">
      <div class="form-group">
      <label v-if="field.params.showlabel">{{field.title}}</label>
      <VueDatePicker v-model="$store.state.activeItems.customer.customFields[groupId].fields[field.name].field_value" model-type="yyyy-MM-dd" format="dd.MM.yyyy" :placeholder="field.title"  :auto-apply="true" :close-on-auto-apply="true" locale="de"    :enable-time-picker="false" cancel-text="X"  select-text="Wählen" :teleport="true"   >
      </VueDatePicker>
      </div>
    </template>
    <template v-if="field.type === 'radio'">
      <div class="form-group">
      <label v-if="field.params.showlabel" class="w-100">{{field.title}}</label>
      <div class="btn-group btn-group-toggle mb-3" data-toggle="buttons">
        <label class="btn btn-outline-primary" v-for="option in field.fieldparams.options" :key="option.value" :class="(loadedForm[field.name] === option.value) ? 'active' : ''" >
          <input type="radio" v-model="$store.state.activeItems.customer.customFields[groupId].fields[field.name].field_value" :value="option.value" autocomplete="off"> {{option.name}}
        </label>
      </div>
      </div>
    </template>
    <template v-if="field.type === 'checkbox'">
      <div class="form-group">
      <label v-if="field.params.showlabel" class="w-100">{{field.title}}</label>
      <div class="btn-group btn-group-toggle mb-3" data-toggle="buttons">
        <label class="btn btn-outline-primary" v-for="option in field.fieldparams.options" :key="option.value" :class="(loadedForm[field.name] === option.value) ? 'active' : ''" >
          <input type="checkbox" v-model="$store.state.activeItems.customer.customFields[groupId].fields[field.name].field_value" :value="option.value" autocomplete="off"> {{option.name}}
        </label>
      </div></div>
    </template>
<!--<pre>{{field}}</pre>-->
  </template>

</template>
<script>
import {mapGetters} from "vuex";

import VueDatePicker from '@vuepic/vue-datepicker';
import axios from "axios";
export default {
  name: "fields",
  props: {
    form: Object,
    groupId: String
  },
  data() {
    return {
      customerform: [],
      loadedForm: {}
    };
  },
  components: {
    VueDatePicker
  },
  computed: {
    ...mapGetters(["customerActive", 'getPlugins']),
  },
  created() {
    this.getPluginData()
    },
  methods: {
    getPluginData() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "plugins/customerfields", {headers}).then((r) => {
        this.customerform = r.data.result.data;
      });
    }
  }

}
</script>
<style scoped>
</style>
