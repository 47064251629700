<template>
  <div class="card-body draft-list project-list">
    <p class="text-sm text-secondary" v-if="!projectList || projectList.data.length === 0">Es wurden keine Projekte
      gefunden.</p>

    <Sort @sort="resortOrder($event)" />

    <div  class="draft-item" v-for="project in projectList.data"
          :key="project.id">
      <Headline :project="project"/>
    </div>


    <pagination list="projects" listName="projectList" filterName="projectsFilter"  />

  </div>

</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";
import Edit from "@/routing/core/Projects/Edit.vue";
import Details from "@/routing/core/Projects/Details.vue";
import Headline from "@/routing/core/Projects/Layouts/Headline.vue";
import Sort from "@/routing/core/Projects/Layouts/Sort.vue";
import Pagination from "@/filter/components/pagination.vue";

export default {
  name: "List",
  emits: ['refresh-project-list', 'new-project', 'project-active'],
  components: {Pagination, Sort, Headline, Details, Edit},
  props: {

  },
  data() {
    return {
      newProjectItem: {

      },
      accessData: [],
      projects:[]

    }
  },
  computed: {
    ...mapGetters(["getEmployees",  "modalsList" , "projectList", "projectActive"])
  },
  methods:{
    setProject(project) {
      this.$emit('new-project', project);
      this.$store.commit('openModal', 'project');
    },
    deleteProject(project) {
      this.$confirm('Soll der Eintrag wirklich gelöscht werden?', 'Projekt löschen', 'warning', {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        type: 'warning'
      }).then(() => {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + "project/" + project.id, {headers}).then((r) => {
          this.$emit('refresh-project-list');
        });
      });
    },
    toggleProject(id) {
      if (this.projectActive === id) {
        this.accessData = [];
        this.$emit('project-active', '');
      } else {
        this.getAccessData();
        this.$emit('project-active', id);
      }
    },
    getAccessData() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.customerActive.id + "/accessData", {headers}).then((r) => {
        this.accessData = r.data.result.data;
      });
    },
    resortOrder(payload) {
      if(payload.direction) {
        this.$store.state.filters.projects.ordering.value = payload.type;
        this.$store.state.filters.projects.direction.value = payload.direction;
      } else {
        this.$store.state.filters.projects.ordering.value = payload.type
      }
      this.$store.dispatch("projectsFilter");
    },
  },
  created() {
    this.$store.dispatch("projectsFilter");
  },
  watch: {

  }
}
</script>
<style scoped>
</style>
