<template>
    <VueDatePicker v-model="$store.state.filters[type][filter.name].value" model-type="yyyy-MM-dd" format="dd.MM.yyyy" :placeholder="langvars[filter.name]"  :auto-apply="true" :close-on-auto-apply="true" locale="de"    :enable-time-picker="false" cancel-text="X"  select-text="Wählen" :teleport="true"  @update:model-value="$store.dispatch(type+'Filter');" class="small" @open="calendarActive = true" @closed="calendarActive = false" :class="(calendarActive) ? 'active' : ''"  >

    </VueDatePicker>
</template>
<script>

import {mapGetters} from 'vuex';
import VueDatePicker from '@vuepic/vue-datepicker';
export default {
  name: "datetime",
  components:{
    VueDatePicker
  },
  data() {
    return {
      customers: "",
      options: [],
      calendarActive: false,
      langvars: {
        creationDateFrom: "Erstellungsdatum von",
        creationDateTo: "Erstellungsdatum bis",
        datefrom: "Datum von",
        dateto: "Datum bis",
      },
      date: null
    };
  },
  props: {
    type: {
      type: String,
      required: true
    },
    filter: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(["getConfigs"]),
  },
  created() {
    this.options = this.getConfigs[this.filter.source];
  }
}
</script>

