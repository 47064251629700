<template>
  <CodeEditor v-model="content" width="100%" height="500" :wrap="true"
              :languages="languages"> </CodeEditor>
</template>
<script>
import CodeEditor from 'simple-code-editor';

export default {
  name: 'EditorHtml',
  props: {
    value: String,
    languages: Array
  },
  data() {
    return {
      content: this.value,
      languages_fallback: [
        ['html', 'HTML'],
        ['javascript', 'JS'],
        ['php', 'PHP'],
        ['css', 'CSS']
      ]
    }
  },
  components: {
    CodeEditor
  },
  created() {
    if (this.languages === undefined) {
      this.languages = this.languages_fallback;
    }
  },
}
</script>
