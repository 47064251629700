<template>
  <div class="modal show" style="display: block;">
    <div class="modal-dialog modal-lg ">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <template v-if="address.id">
              {{address.c_firstname}} {{address.c_lastname}} {{$t("contact.editSmall")}}
            </template>
            <template v-else>
            <template v-if="customerActive.c_company">{{$t("createContact.newContactFor")}} {{customerActive.c_company}}</template>
            <template v-else>{{$t("createContact.createNewContact")}}</template>
            </template>
          </h5>
          <button type="button" class="btn-close" @click="resetAddress()"></button>
        </div>
        <div class="modal-body">
          <div class=" addContact">
            <div class="row mb-2">
              <div class="col-12 col-xl-2"><label>{{$t("globals.salutation")}}</label>
                <input type="text" class="form-control" v-model="address.c_title">
              </div>
              <div class="col-12 col-xl-5"><label>{{$t("globals.firstName")}}</label>
                <input type="text" class="form-control" v-model="address.c_firstname">
              </div>
              <div class="col-12 col-xl-5"><label>{{$t("globals.surname")}}</label>
                <input type="text" class="form-control" v-model="address.c_lastname">
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12 col-xl-6"><label>{{$t("globals.email")}}</label>
                <input type="text" class="form-control" v-model="address.c_email">
              </div>
              <div class="col-12 col-xl-6"><label>{{$t("globals.function")}}</label>
                <input type="text" class="form-control" v-model="address.funktion">
              </div>
            </div>
            <div class="row mb-2">

              <div class="col-12 col-xl-4"><label>{{$t("globals.phone")}}</label>
                <input type="text" class="form-control" v-model="address.c_phone">
              </div>
              <div class="col-12 col-xl-4"><label>Telefon geschäftlich</label>
                <input type="text" class="form-control" v-model="address.c_phone2">
              </div>
              <div class="col-12 col-xl-4"><label>{{$t("globals.mobilePhone")}}</label>
                <input type="text" class="form-control" v-model="address.c_handy">
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-6">
                <div class="form-group">
                  <label>{{$t("globals.street")}}</label>
                  <input class="form-control" v-model="address.c_street">
                </div>
              </div>
              <div class="col-12 col-lg-2">
                <div class="form-group">
                  <label>{{$t("globals.zipCode")}}</label>
                  <input class="form-control" v-model="address.c_zipcode">
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="form-group">
                  <label>{{$t("globals.location")}}</label>
                  <input class="form-control" v-model="address.c_city">
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12 col-xl-6">
                <label>{{$t("globals.postOfficeBox")}}</label>
                <input type="text" class="form-control" v-model="address.c_mailbox">
              </div>
              <div class="col-12 col-xl-6">
                <label>{{$t("globals.state")}}</label>

                <Multiselect v-model="address.c_country"
                             :options="getSelectedCountries"
                             :valueProp="'id'"
                             :multiple="false"
                             :canClear="false"
                             :label="'lang_name'"
                             :closeOnSelect="true"
                             :searchable="false"
                             :placeholder="'Land wählen'"
                             :trackBy="'lang_name'"
                ></Multiselect>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12 col-xl-6"><label>Firma/Verein</label>
                <input type="text" class="form-control" v-model="address.c_company">
              </div>
              <div class="col-12 col-xl-6"><label>Namenszusatz</label>
                <input type="text" class="form-control" v-model="address.c_suffix">
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12 col-xl-6">
                <label>Geburtstag</label>
                <VueDatePicker v-model="address.c_birthday" model-type="yyyy-MM-dd" format="dd.MM.yyyy" :placeholder="$t('globals.selectDateOfBirth')"  :auto-apply="true" :close-on-auto-apply="true" locale="de"    :enable-time-picker="false" cancel-text="X"  :select-text="this.$t('glboals.choose')"  :teleport="true"   >

                </VueDatePicker>
              </div>
              <div class="col-12 col-xl-6">
                <label>Geburtsort</label>
                <input type="text" class="form-control" v-model="address.birthday_city">
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12 col-xl-6">
                <label class="me-3">Standard Rechnungsadresse</label>
              <div class="btn-group btn-group-yesno" role="group"  >
                <input type="radio" class="btn-check" name="use_own_mailing" id="use_own_mailing_yes" value="1"
                       v-model="address.default_invoice">
                <label class="btn btn-outline-primary" for="use_own_mailing_yes">Ja</label>
                <input type="radio" class="btn-check" name="use_own_mailing" id="use_own_mailing_no" value="0"
                       v-model="address.default_invoice">
                <label class="btn btn-outline-danger" for="use_own_mailing_no">Nein</label>
              </div>
                </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">

          <div class="row w-100">
            <div class="col">
              <button type="button" class="btn btn-outline-secondary" @click="resetAddress()">
                <i class="fa-duotone fa-xmark-circle"></i> {{$t("globals.cancel")}}
              </button>
            </div>
            <div class="col" style="text-align: right;">

              <button type="button" class="btn btn-outline-primary " @click="editAddress()"
                      v-if="address.id">
                <i class="fa-duotone fa-floppy-disk"></i> {{$t("globals.save")}}
              </button>
              <button type="button" class="btn btn-outline-primary" @click="postAddress()" v-else><i class="fa-duotone fa-floppy-disk"></i> {{$t("globals.save")}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";
import VueDatePicker from "@vuepic/vue-datepicker";
import Multiselect from "@vueform/multiselect";
export default {
  name: "CreateContact",
  components: {VueDatePicker, Multiselect},
  computed: {
    ...mapGetters(["customerActive", "modalsList", "getConfigs", "getAddress", "getSelectedCountries"]),
  },
  data() {
    return {
      address: {
        id: "",
        c_suffix:"",
        c_title: "",
        c_firstname: "",
        c_lastname: "",
        c_email: "",
        c_phone: "",
        c_phone2: "",
        c_handy: "",
        c_fax:"",
        c_country: "",
        c_street: "",
        c_zipcode: "",
        c_city: "",
        c_mailbox: "",
        c_branch: "",
        c_company:"",
        c_birthday:"",
        birthday_city:"",
        c_gender:"",
        funktion: "",
        default_invoice: 0
      },
      addresses: [],
    }
  },
  methods: {
    cancelEdit() {

      this.$store.commit("closeModal", "address");
    },

    resetAddress(){
      this.address = {
        id: "",
        c_suffix:"",
        c_title: "",
        c_firstname: "",
        c_lastname: "",
        c_email: "",
        c_phone: "",
        c_phone2: "",
        c_handy: "",
        c_fax:"",
        c_country: "",
        c_street: "",
        c_zipcode: "",
        c_city: "",
        c_mailbox: "",
        c_branch: "",
        c_company:"",
        birthday_city:"",
        c_gender:"",
        funktion: "",
        default_invoice: 0
      }
      this.$store.commit("setAddress", this.address)
      this.cancelEdit();
    },
    editAddress() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let editedAddress = {
        hash: this.customerActive.hash,
        data:  this.address

      }
      axios.put(this.$store.state.apiUrl + "addresses/" + this.address.id, editedAddress, {headers}).then((r) => {
        this.$store.commit("setAddresses", {data: r.data.result.data, customer_id: this.customerActive.id});
        this.$store.dispatch("toastSuccess", this.$t("toast.success.editSuccess"));
        this.resetAddress();
      }).catch((r) => {
        this.$store.dispatch("handleApiResponse", r.response);
        this.resetAddress();
      });
    },
    postAddress() {
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let postContent = {
        hash: this.customerActive.hash,
        data: this.address
      }
      axios.post(this.$store.state.apiUrl + "addresses", postContent, {headers}).then((r) => {
        this.$store.commit("setAddresses", {data: r.data.result.data, customer_id: this.customerActive.id});
        this.$store.dispatch("toastSuccess", this.$t("toast.success.saveSuccess"));
        this.$store.commit("setAddress", false);
        this.resetAddress();
      }).catch((r) => {
        this.$store.dispatch("handleApiResponse", r.response);
        this.resetAddress();
      });

    },

    checkAddress() {
      if (this.modalsList.address) {
        this.address = this.getAddress;
      }
    }
  },
  mounted() {
    this.checkAddress();
  },

}
</script>
<style scoped>
</style>
