<template>
  <div class="task-item headline">
  <div class="taskLineWrap" >
    <div class="lineitem first l-check">
      <span @click="emitSort('checktask')"  :class="(sortDirections.checktask) ? 'active' : ''"></span>
      <i v-if="sortDirections.checktask === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.checktask === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-title">
      <span @click="emitSort('title')"  :class="(sortDirections.title) ? 'active' : ''">Aufgaben</span>
      <i v-if="sortDirections.title === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.title === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>

    <div class="lineitem l-subtasks">
      <span @click="emitSort('_countIssues')" :class="(sortDirections._countIssues) ? 'active' : ''" tooltip="Unteraufgaben" :position="'left'"><i class="fa-duotone fa-list-tree"></i></span>
      <i v-if="sortDirections._countIssues === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections._countIssues === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-attachments" >
      <span @click="emitSort('_countAttachments')"  :class="(sortDirections._countAttachments) ? 'active' : ''" tooltip="Anhang" :position="'left'"><i class="fa-duotone fa-paperclip"></i></span>
      <i v-if="sortDirections._countAttachments === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections._countAttachments === 'desc'" class="fa-duotone fa-arrow-down"></i>

    </div>
    <div class="lineitem l-comments">
      <span @click="emitSort('_countComments')" :class="(sortDirections._countComments) ? 'active' : ''" tooltip="Kommentare" :position="'left'"><i class="fa-duotone fa-comments"></i></span>
      <i v-if="sortDirections._countComments === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections._countComments === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-times">
      <span @click="emitSort('_sumTimesComplete')" :class="(sortDirections.issuePriorityTitle) ? 'active' : ''" tooltip="Zeitaufwand" position="left"><i class="fa-duotone fa-stopwatch"></i></span>
      <i v-if="sortDirections.issuePriorityTitle === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.issuePriorityTitle === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-prio">
      <span @click="emitSort('issuePriorityTitle')" :class="(sortDirections.issuePriorityTitle) ? 'active' : ''" tooltip="Priorität" :position="'left'"><i class="fa-duotone fa-star"></i></span>
      <i v-if="sortDirections.issuePriorityTitle === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.issuePriorityTitle === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-state">
      <span @click="emitSort('issueStateTitle')" :class="(sortDirections.issueStateTitle) ? 'active' : ''" tooltip="Status" :position="'left'"><i class="fa-duotone fa-timeline"></i></span>
      <i v-if="sortDirections.issueStateTitle === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.issueStateTitle === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>

    <div class="lineitem l-date">
      <span @click="emitSort('due_date')" :class="(sortDirections.due_date) ? 'active' : ''" tooltip="Abgabedatum" :position="'left'">Datum</span>
      <i v-if="sortDirections.due_date === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.due_date === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-customer" >
<span>Kunde</span>
    </div>
    <div class="lineitem l-customer projects">
    <span>Projekt</span>
    </div>
    <div class="lineitem l-date lastChange">
      <span>Aktivität</span>
    </div>
    <div class="lineitem l-actions ">
      </div>
    <div class="lineitem last  ">

    </div>
  </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";


export default {
  name: "Sort",
  emits: ['sort'],
  data() {
    return {
      modalContent: null,
      toast: useToast(),
      address: {},
      sortDirections:{
        title: '',
        id: '',
        _countAttachments:'',
        _countIssues :'',
        _countComments:'',
        issuePriorityTitle:'',
        employee_id:'',
        issueStateTitle:'',
        estimated_hours:'',
        due_date:'',

      }

    }
  },
  props: {
    customer: Object,
  },
  computed: {
    ...mapGetters(["customerActive", "getEmployees", "getConfigs", "taskActive" ])
  },
  methods: {
    emitSort(clickedType) {
      // Reset all sort directions to the default state except for the clickedType
      for (let type in this.sortDirections) {
        if (type !== clickedType) {
          this.sortDirections[type] = '';
        }
      }

      // Toggle the clicked type's sort direction based on your logic
      if (this.sortDirections[clickedType] === '') {
        this.sortDirections[clickedType] = 'desc';
      } else if (this.sortDirections[clickedType] === 'desc') {
        this.sortDirections[clickedType] = 'asc';
      } else {
        this.sortDirections[clickedType] = '';
      }

      // Emit the clicked type and direction
      this.$emit('sort', { type: clickedType, direction: this.sortDirections[clickedType] });
    }
  },

}
</script>
<style lang="scss">
span.iconAction {
  font-size: 1rem;
  &.disabled {
    display: none;
  }
  border: 0 !important
}

</style>
