<template>
  <template v-if="getPlugins.phone && getPlugins.phone.placetel && getUser.phone_ip && phone">
    <a :href="'http://'+getUser.phone_ip+'/servlet?key=number='+phone" class="callNumber" target="_blank" :tooltip="phone+' direkt anrufen'" :position="'right'"><i
      class="fa-duotone fa-phone-volume"></i></a>
  </template>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "callnow",
  computed: {
    ...mapGetters(["getEmployees", "customerActive", "modalsList", "addressList", "getPlugins", "getUser"]),
  },
  props: {
    phone: ""
  },
  components: {},
  methods: {},
}
</script>
<style scoped>
</style>
