
<template>
  <table class="table-sm table table-striped">
    <thead>
    <tr>
      <th>Anlagedatum</th>
      <th>Name</th>
      <th>PLZ</th>
      <th>Stadt</th>
      <th>Land</th>
      <th>Typ</th>
      <th>Mitarbeiter</th>
      <th>Projektliste</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(value, key) in tableData" >
      <td>{{ $filters.formatDate(value.created_at)}}</td>
      <td>{{ value.lastname }} {{ value.firstname }}</td>
      <td>{{ value.zipcode }}</td>
      <td>{{ value.city }}</td>
      <td>{{ value.country }}</td>
      <td>{{ value.cType }}</td>
      <td :style="{backgroundColor: value.employeeColor, color:'#fff'}">{{ value.employeeName }}</td>
      <td>{{value.ust}}</td>
    </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: 'StatsTable',
  props: {

    tableData: {
      type: Object,
      required: true
    },
  },
}
</script>
<style scoped>
</style>
