<template>
  <div class="draft-item headline">
  <div class="draftLineWrap" >
    <div class="lineitem first l-checkbox">
      <span @click="emitSort('id')"  :class="(sortDirections.id) ? 'active' : ''">ID</span>
      <i v-if="sortDirections.id === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.id === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>

    <div class="lineitem l-title">
      <span @click="emitSort('title')"  :class="(sortDirections.title) ? 'active' : ''">Titel</span>
      <i v-if="sortDirections.title === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.title === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>

    <div class="lineitem l-default" >
      <span @click="emitSort('iban')"  :class="(sortDirections.iban) ? 'active' : ''">IBAN</span>
      <i v-if="sortDirections.iban === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.iban === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>

    <div class="lineitem l-default">
      <span @click="emitSort('money')" :class="(sortDirections.money) ? 'active' : ''">Kontostand</span>
      <i v-if="sortDirections.money === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.money=== 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>

    <div class="lineitem l-start_month">
      <span @click="emitSort('start_month')" :class="(sortDirections.start_month) ? 'active' : ''">Erstellt am</span>
      <i v-if="sortDirections.start_month === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.start_month === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>

    <div class="lineitem l-default">
      <span @click="emitSort('konto')" :class="(sortDirections.konto) ? 'active' : ''">Konto</span>
      <i v-if="sortDirections.konto === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.konto === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-state ml-auto">
      <span @click="emitSort('default')" :class="(sortDirections.default) ? 'active' : ''">Standard</span>
      <i v-if="sortDirections.default === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.default === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>

    <div class="lineitem last  ">

    </div>
  </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";
import axios from "axios";

export default {
  name: "Sort",
  emits: ['sort'],
  data() {
    return {
      modalContent: null,
      toast: useToast(),
      address: {},
      sortDirections:{
        title: '',
        konto: '',
        start_month: '',
        money: '',
        iban: '',
        id:'',
      }

    }
  },
  props: {
    draft: Object,
  },
  computed: {
    ...mapGetters(["draftActive", "getEmployees", "getConfigs"])
  },
  methods: {
    emitSort(clickedType) {
      // Reset all sort directions to the default state except for the clickedType
      for (let type in this.sortDirections) {
        if (type !== clickedType) {
          this.sortDirections[type] = '';
        }
      }

      // Toggle the clicked type's sort direction based on your logic
      if (this.sortDirections[clickedType] === '') {
        this.sortDirections[clickedType] = 'desc';
      } else if (this.sortDirections[clickedType] === 'desc') {
        this.sortDirections[clickedType] = 'asc';
      } else {
        this.sortDirections[clickedType] = '';
      }

      // Emit the clicked type and direction
      this.$emit('sort', { type: clickedType, direction: this.sortDirections[clickedType] });
    }
  },

}
</script>
<style lang="scss">
span.iconAction {
  font-size: 1rem;
  &.disabled {
    display: none;
  }
  border: 0 !important
}

</style>
