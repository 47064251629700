<template>
  <div class="draft-item headline">
  <div class="draftLineWrap projectLine" >
    <div class="lineitem first l-checkbox">
      <span @click="emitSort('id')"  :class="(sortDirections.id) ? 'active' : ''">ID</span>
      <i v-if="sortDirections.id === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.id === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-title">
      <span @click="emitSort('title')"  :class="(sortDirections.title) ? 'active' : ''">Titel</span>
      <i v-if="sortDirections.title === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.title === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-state">
      <span @click="emitSort('state')"  :class="(sortDirections.state) ? 'active' : ''">Status</span>
      <i v-if="sortDirections.state === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.state === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <Holzkraft mode="listSort"  v-if="getPlugins && getPlugins.infos && getPlugins.infos.holzkraft" />
    <div class="lineitem l-avatar ml-auto" >
      <span @click="emitSort('employee_id')"  :class="(sortDirections.employee_id) ? 'active' : ''">Mitarbeiter</span>
      <i v-if="sortDirections.categoryTitle === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.categoryTitle === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>



    <div class="lineitem last  ">

    </div>
  </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";
import Holzkraft from "@/routing/plugins/holzkraft/subs/projects.vue";


export default {
  name: "Sort",
  components: {Holzkraft},
  emits: ['sort'],
  data() {
    return {
      modalContent: null,
      toast: useToast(),
      address: {},
      sortDirections:{
        title: '',
        id: '',
        categoryTitle:'',

      },
      projectData: {}

    }
  },
  props: {
    project: Object,
  },
  computed: {
    ...mapGetters(["projectActive", "getPlugins", "getEmployees", "getConfigs"])
  },
  methods: {
    emitSort(clickedType) {
      // Reset all sort directions to the default state except for the clickedType
      for (let type in this.sortDirections) {
        if (type !== clickedType) {
          this.sortDirections[type] = '';
        }
      }

      // Toggle the clicked type's sort direction based on your logic
      if (this.sortDirections[clickedType] === '') {
        this.sortDirections[clickedType] = 'desc';
      } else if (this.sortDirections[clickedType] === 'desc') {
        this.sortDirections[clickedType] = 'asc';
      } else {
        this.sortDirections[clickedType] = '';
      }

      // Emit the clicked type and direction
      this.$emit('sort', { type: clickedType, direction: this.sortDirections[clickedType] });
    }
  },
  created() {
    if(this.project) {
      this.projectData = this.project;
    }
  }

}
</script>
<style lang="scss">
span.iconAction {
  font-size: 1rem;
  &.disabled {
    display: none;
  }
  border: 0 !important
}

</style>
