<template>
  <div class="card-body draft-list">
      <p class="text-sm text-secondary" v-if="!getConfigs || getConfigs.companyPlanAccounting.length === 0">Es wurden keine Vorlagen
        gefunden.</p>
    <Sort @sort="resortOrder($event)" />
      <div  class="draft-item" v-for="draft in getConfigs.companyPlanAccounting"
           :key="draft.id">
        <Headline :draft="draft"/>
      </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Details from "@/routing/core/Config/Accounting/YearPlan/Details.vue";
import Headline from "@/routing/core/Config/Accounting/YearPlan/Layouts/Headline.vue";
import Sort from "@/routing/core/Config/Accounting/YearPlan/Layouts/Sort.vue";
import $ from "jquery";

export default {
  name: "YearPlanList",
  computed: {
    ...mapGetters(["getEmployees","getConfigs"])
  },

  components: {
    Sort,
    Headline,
    Details,
  },
  methods: {

    getYearPlans(){

      this.$store.dispatch("yearPlanFilter");
    },
    resortOrder(payload) {
      if(payload.direction) {
        this.$store.state.filters.yearPlan.ordering.value = payload.type;
        this.$store.state.filters.yearPlan.direction.value = payload.direction;
      } else {
        this.$store.state.filters.yearPlan.ordering.value = payload.type
      }
      this.getYearPlans();
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  created() {
  },
  watch: {
    $route(to, from) {
    },
    draftActive() {
    }
  }

};
</script>
