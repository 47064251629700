<template>
  <div class="card">
    <div class="card-header card-header-inline">
      <h6 class="editLine">Anmeldungen</h6>
      <div class="btn-group float-end">
        <button @click="addMemberPopup = true" class="btn btn-outline-primary btn-sm"><i
          class="fa-duotone fa-user-plus"></i> Teilnehmer hinzufügen
        </button>
        <button @click="exportMembers" class="btn btn-outline-secondary btn-sm"><i
          class="fa-duotone fa-file-export"></i> CSV Exportieren
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="registrations" v-if="$route.params.id">
        <div class="filters">
          <div class="filter">
            <label for="filter_search">Suche</label>
            <input type="text" id="filter_search" class="form-control small" @keyup.enter="getRegistrations"
                   v-model="filter_search">
          </div>
          <div class="filter">
            <label for="filter_state">Status</label>
            <select name="filter_sketchdates_state" @change="getRegistrations" class="small"
                    id="filter_sketchdates_state" v-model="filter_sketchdates_state">
              <option selected="" value="">- Anmeldestatus (alle) -</option>
              <option value="aw">Angemeldet + Warteliste</option>
              <option value="a">- Angemeldet -</option>
              <option value="w">- Warteliste -</option>
              <option value="s">- Storniert -</option>
            </select>
          </div>
        </div>
        <table class="table-striped table">
          <thead>
          <tr>
            <th>ID</th>
            <th>Status</th>
            <th class="center">Anzahl</th>
            <th>Datum</th>
            <th>Infos</th>
            <th>Kontakt</th>
            <th>Aktionen</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="r in registrations">
            <td>
              {{ r.id }}
            </td>
            <td>
              {{ r.formatted.state }}
            </td>
            <td class="center">
              {{ r.quota }}
            </td>
            <td>{{ $filters.formatDate(r.registration_date) }}</td>
            <td><small>{{ r.profile.suffix }} {{ r.profile.firstname }} {{ r.profile.lastname }}
              <template v-if="r.profile.street || r.profile.city"><br>{{ r.profile.street }} {{ r.profile.city }}
              </template>
            </small>
            </td>
            <td><small>{{ r.email }}
              <template v-if="r.profile.phone"><br>{{ r.profile.phone }}</template>
              <template v-if="r.profile.mobile"><br>{{ r.profile.mobile }}</template>
            </small></td>
            <td>
              <div class="btn-group">
                <button @click="stornoMember(r.id,'stornoData')" v-if="r.state === '1' || r.state === '2'"
                        class="btn btn-outline-danger btn-sm"><i class="fa-duotone fa-xmark-circle"></i> Stornieren
                </button>
                <button @click="stornoMember(r.id,'snoozeData')" v-if="r.state === '1'"
                        class="btn btn-outline-secondary btn-sm"><i class="fa-duotone fa-eye-slash"></i> No Show
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <Modal v-if="addMemberPopup">
          <div class="modal show " style="display: block;">
            <div class="modal-dialog modal-lg">
              <div class="modal-content" style="min-height: 80vh;">
                <div class="modal-header">
                  <button class="btn-close" @click="addMemberPopup = false"></button>
                </div>
                <div class="modal-body">
                    <customersearch v-model="customer"/>
                    <button class="btn btn-outline-primary" @click="addMember" v-if="customer && customer.id"><i class="fa-duotone fa-plus"></i> Hinzufügen</button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <div class="noId" v-else>
        <h3>Bitte wähle eine Veranstaltung aus</h3>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import customersearch from "@/filter/components/customersearch.vue";

export default {
  name: 'Registrations',
  components: {customersearch},
  data() {
    return {
      registrations: [],
      customer: null,
      addMemberPopup: false,
      filter_sketchdates_state: '',
      filter_search: '',
    }
  },
  computed: {
    ...mapGetters(["modalsList", "eventActive"])
  },
  methods: {
    stornoMember($regId, type) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let payload = {
        activeDate: this.$route.params.id,
        activeSubDate: null,
        reg: $regId,
        type: type,
      }
      axios.post(this.$store.state.apiUrl + "date", payload, {headers}).then((r) => {
        this.$store.dispatch('toastSuccess', this.$t("toast.success.dataSaved"));
        this.getRegistrations();
      });
    },
    exportMembers() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const filters = '?filter_sketchdates_state=' + this.filter_sketchdates_state + '&filter_search=' + this.filter_search;
      axios.get(this.$store.state.apiUrl + "event/" + this.eventActive.id + "/registrations/export" + filters, {headers: headers})
        .then((response) => {
          const file = response.data.result.data;
          const linkSource = 'data:text/csv;base64,' + file;
          // download fileName
          // trigger download
          var a = window.document.createElement('a');
          a.setAttribute('href', linkSource);
          a.setAttribute('download', 'export_' + this.eventActive.title + '.csv');
          window.document.body.appendChild(a);
          a.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRegistrations() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const filters = '?filter_sketchdates_state=' + this.filter_sketchdates_state + '&filter_search=' + this.filter_search;
      axios.get(this.$store.state.apiUrl + "event/" + this.eventActive.id + "/registrations" + filters, {headers: headers})
        .then((response) => {
          this.registrations = response.data.result.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addMember() {
      const payload = {}
      payload.dateId = this.$route.params.id;
      payload.sendMail = 'yes';
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.state.apiUrl + "customer/" + this.customer.id + "/addEvent", payload, {headers}).then((r) => {
        this.$store.dispatch('toastSuccess', this.$t("toast.success.dataSaved"));
        this.getRegistrations();
        this.addMemberPopup = false;
      });
    }
  },
  created() {
    this.getRegistrations();
  },
}
</script>
<style>
</style>
