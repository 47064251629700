<template>
  <template v-if="$route && $route.matched && $route.matched.length">
    <template v-if="$route.matched[0].name === 'customers'">
      <button class="btn btn-outline-primary btn-sm me-3 btn-hide-mobile" :tooltip="$t('import.customers')" position="left"
              @click="$store.commit('openModal', 'import');"><i
        class="fa-duotone fa-file-import fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.import">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="productItem activeProduct">
              <div class="card">
                <div class="card-header ">
                  <button type="button" class="btn btn-close float-end ms-4" style="position: relative;
z-index: 1;"
                          @click="$store.commit('closeModal', 'import');">
                  </button>
                  <h6 class="inline"><i class="fa-duotone fa-square-info"></i> {{ $t("import.customers") }}</h6>
                </div>
                <div class="card-body">
                  <CsvImport :fields="customerFields" :list-name="'customers'"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </template>
  </template>
</template>
<script>
import CsvImport from "@/directives/Upload/CsvImport.vue";
import {mapGetters} from "vuex";

export default {
  name: "import",
  data() {
    return {
      importdata: {},
      customerFields: {
        c_customer_id: {
          required: false,
          label: 'Kundennummer'
        },
        c_company: {
          required: false,
          label: 'Firma'
        },
        c_title: {
          required: false,
          label: 'Anrede'
        },
        c_lastname: {
          required: true,
          label: 'Name'
        },
        c_firstname: {
          required: false,
          label: 'Vorname'
        },
        c_email: {
          required: false,
          label: 'E-Mail'
        },

        c_street: {
          required: false,
          label: 'Straße'
        },
        c_zip: {
          required: false,
          label: 'PLZ'
        },
        c_city: {
          required: false,
          label: 'Ort'
        },
        c_country: {
          required: false,
          label: 'Land'
        },

        c_phone: {
          required: false,
          label: 'Telefon'
        },
        c_mobile: {
          required: false,
          label: 'Mobil'
        },
        c_fax: {
          required: false,
          label: 'Fax'
        },
        c_website: {
          required: false,
          label: 'Website'
        },
        c_ustid: {
          required: false,
          label: 'USt-ID'
        },

        structure1: {
          required: false,
          label: 'Struktur 1'
        },
        structure2: {
          required: false,
          label: 'Struktur 2'
        },
        structure3: {
          required: false,
          label: 'Struktur 3'
        },
        structure4: {
          required: false,
          label: 'Struktur 4'
        },
      }
    };
  },
  components: {CsvImport},
  computed: {
    ...mapGetters(['modalsList']),
  },
  methods: {

  }
}
</script>
<style scoped>
</style>
