<template>
  <div class="projects-item">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-12 col-md mb-2 mb-md-0"><h6>{{$t("projects.project")}}</h6></div>
          <div class="col-12 col-md mb-2 mb-md-0">
            <Multiselect
              v-model="stateFilter"
              :options="states"
              class="small"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              @update:model-value="getProjects"
              :select-label="$t('projects.label.choosestate')"
              :deselect-label="$t('projects.label.delete')"
              :selectedLabel="$t('projects.label.selected')"
              :placeholder="$t('projects.label.filterState')"
              label="label"
              track-by="value"
              :preselect-first="true"/>
          </div>
          <div class="col-12 col-md mb-2 mb-md-0">
            <Multiselect
              class="small"
              v-model="projectFilter"
              :options="projects"
              :multiple="false"
              @update:model-value="getProjects"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              :select-label="$t('projects.label.chooseProject')"
              :deselect-label="$t('projects.label.delete')"
              :showNoOptions="false"
              :selectedLabel="$t('projects.label.selected')"
              :placeholder="$t('projects.label.chooseProject')"
              label="title"
              valueProp="id"
              track-by="title"
              :preselect-first="true"/>
          </div>
          <div class="col-12 col-md-4">
            <div class="btn-group float-end">
              <button class="btn  btn-outline-primary btn-sm" @click="startNewProject" v-if="activeSubtab === 'infos'"><i
                class="fa-duotone fa-diagram-project"></i> {{$t("projects.newProject")}}
              </button>
              <button class="btn btn-outline-primary btn-sm" @click="newTask = true;" v-if="activeSubtab === 'tasks'"><i
                class="fa-duotone fa-ticket"></i>
                {{$t("projects.newTask")}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <ul class="tabNav subtabs">
          <li v-for="cat in projectTabs" :key="cat.name"
              @click="$router.push({name:'CustomersTabsSubtabs', params:{id:customerActive.id,tab:$route.params.tab, subtab: cat.name}}); activeSubtab = cat.name"
              :class="(activeSubtab === cat.name) ? 'active' : ''"><i class="fa-duotone" :class="cat.icon"></i>
            {{ cat.label }}
          </li>
        </ul>
        <div class="allTaskWrap" v-if="activeSubtab === 'tasks'">

          <div class="task-list">
              <p class="text-sm text-secondary" v-if="!taskList.data.length">
                {{$t('tasks.list.tasksNotFound')}} </p>
              <div class="taskWrap" v-for="task in taskList.data" :class="(taskActive && taskActive.id === task.id) ? 'activeTask' : 'notActive'"
                   :key="task.id">
                <THeadline :task="task"/>
              </div>

            <pagination list="tasks" listName="taskList" filterName="tasksFilter"  />
          </div>

        </div>
        <div class="projectInfoWrap" v-if="activeSubtab === 'infos'" >
          <template v-for="project in projects"  :key="project.id">
            <List :project="project" :project-active="projectActive" @refresh-project-list="refreshProjects" @new-project="setNewProject" @project-active="setActiveProject" />
          </template>
          <Modal v-if="modalsList.project">
            <Edit :newProjectItem="newProjectItem" @refresh-project-list="refreshProjects" />
          </Modal>
        </div>
      </div>
    </div>

    <Modal v-if="newTask">
      <CreateTask :taskActive="taskActive" @closeTask="newTask = false"/>
    </Modal>
  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import VueDatePicker from '@vuepic/vue-datepicker';
import Editor2 from "@/components/Editor2";
import CustomerPasswords from "@/routing/core/Customers/Passwords.vue";
import Details from "@/routing/core/Projects/Details.vue";
import List from "@/routing/core/Projects/List.vue";
import Edit from "@/routing/core/Projects/Edit.vue";
import THeadline from "@/routing/core/Tasks/Layouts/Headline.vue";
import CreateTask from "@/routing/core/Tasks/Create.vue";
import Pagination from "@/filter/components/pagination.vue";
import Headline from "@/routing/core/Tasks/Layouts/Headline.vue";
import Multiselect from "@vueform/multiselect";
export default {
  name: "Projects",
  components: {
    Headline, Pagination,
    Edit,
    CustomerPasswords,
    Editor2, VueDatePicker, Multiselect,
    Details, List, THeadline, CreateTask
  },
  data() {
    return {
      projects: [],
      newTask: false,
      newProject: false,
      showPassEdit: false,
      newProjectItem: {
        title: '',
        description: '',
        url_live: '',
        url_dev: '',
        ftp_live: '',
        ftp_dev: '',
        php_live: '',
        php_dev: '',
        url_git: '',
        software: '',
        wartung: 0,
      },
      newTaskItem: {
        title: '',
        description: '',
        due_date: '',
        start_date: '',
        project_id: '',
      },
      accessData: [],
      projectFilter: {id: '', title: this.$t('projects.allProjects')},
      stateFilter: {label: this.$t('globals.open'), value: 'open'},
      projectTabs: [
        {name: 'tasks', label: 'Tasks', icon: 'fa-ticket'},
        {name: 'infos', label: this.$t('projects.projectsDashboard'), icon: 'fa-info-circle'},
      ],
      states: [
        {label: this.$t('globals.open'), value: 'open'},
        {label: this.$t('projects.label.inProcess'), value: 'open'},
        {label: this.$t('projects.label.waitingForCustomer'), value: 'waiting'},
        {label: this.$t('projects.label.deferred'), value: 'reject'},
        {label: this.$t('globals.done'), value: 'closed'},
      ],
      activeSubtab: 'tasks',
      taskActive: {},
      taskActiveDetails: false,
      projectActive: '',
      tasks: [],
      today: new Date().toISOString().slice(0, 10),
      phpversions: [
        {label: '8.2', value: '8.20'},
        {label: '8.1', value: '8.10'},
        {label: '8.0', value: '8.00'},
        {label: '7.4', value: '7.40'},
        {label: '7.3', value: '7.30'},
        {label: '7.2', value: '7.20'},
        {label: '7.1', value: '7.10'},
        {label: '7.0', value: '7.00'},
        {label: '5.6', value: '5.60'},
      ],
      softwares: [
        {label: 'Joomla4'}, {label: "VueJS"}, {label: "Wordpress"}, {label: "Joomla3"}
      ]
    }
  },
  computed: {
    ...mapGetters(["customerActive", "getEmployees",  "modalsList", "taskList"])
  },
  methods: {
    getAccessData() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.customerActive.id + "/accessData", {headers}).then((r) => {
        this.accessData = r.data.result.data;
      });
    },
    setActiveProject(project) {
      this.projectActive = project;
      this.activeSubtab = 'infos';
    },
    setNewProject(project){
      this.newProjectItem = project;
      this.newProject = true;
    },
    getProjects() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "project/search?filter_customer=" + this.customerActive.id + "&filter_state=active&limit=0", {headers}).then((r) => {
        this.projects = r.data.result.data;
        this.getTasks();
      });
    },

    refreshProjects(){
      this.activeSubtab = this.$t('projects.info');
      this.getProjects();
    },
    getTasks() {
      if (!this.projectFilter) {
        this.projectFilter = {id: false, title: this.$t('projects.allProjects')}
      }
      const filters = {
        filter_project: this.projectFilter,
        filter_state: this.stateFilter,
      }
      this.$store.dispatch("tasksFilterCustomer", filters);

    },
    toggleTask(id) {
      if (this.taskActive === id) {
        this.taskActive = false;
      } else {
        this.taskActive = id;
        this.getTaskDetails();
      }
    },
    getTaskDetails() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "issue/" + this.taskActive, {headers}).then((r) => {
        this.taskActiveDetails = r.data.result.data;
      });
    },

    startNewProject() {
      this.newProject = true;
      this.$store.commit('openModal', 'project');
      this.newProjectItem = {
        title: '',
        description: '',
        url_live: '',
        url_dev: '',
        ftp_live: '',
        ftp_dev: '',
        php_live: '',
        php_dev: '',
        url_git: '',
        software: '',
        wartung: 0,
      }
    },

  },
  created() {
    this.getProjects();
    this.getAccessData();
  },
  emits: ['refresh-project-list', 'close-task'],
}
</script>
<style lang="scss" scoped>
select {
  width: 100%;
}

</style>
