<template>
  <div class="card">
    <h6 class="card-header">{{$t("globals.forms")}}
      <button class="btn btn-outline-primary btn-sm" @click="updateCustomer()"><i class="fa-duotone fa-floppy-disk"></i> {{$t("globals.save")}}</button>
    </h6>
    <div class="card-body">
      <ul class="tabNav subtabs">
        <li v-for="(cat,key) in customerform" :key="cat.id"
            @click="setSubtab(cat.id)"
            :class="(activeSubtab === cat.id) ? 'active' : ''">{{ cat.title }}
        </li>
      </ul>
      <template v-for="(cat,key) in customerform">
        <fields :form="cat.fields" :groupId="cat.id" v-if="activeSubtab === cat.id"/>
      </template>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import fields from "@/routing/plugins/fields.vue";
import axios from "axios";

export default {
  name: "CrmPlugins",
  props: {},
  computed: {
    ...mapGetters(["customerActive", 'getPlugins']),
  },
  components: {
    fields
  },
  data() {
    return {
      activeSubtab: "",
      customerform: [],
    }
  },
  methods: {
    setSubtab(subtab) {
      this.activeSubtab = subtab;
      this.$router.push({
        name: 'CustomersTabsSubtabs',
        params: {id: this.customerActive.id, tab: this.$route.params.tab, subtab: subtab}
      })
    },
    getPluginData() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "plugins/customerfields/"+this.customerActive.id, {headers}).then((r) => {
        this.customerform = r.data.result.data;
      });
    },
    updateCustomer() {


      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.state.apiUrl + "customer/" + this.customerActive.id, this.$store.state.activeItems.customer, {headers}).then((r) => {
        this.$store.dispatch('toastSuccess', this.$t("toast.success.dataSaved"));

        this.$store.dispatch('customersFilter');
        this.$store.commit('setActiveItem', {type:'customer',data: r.data.result.data});
        this.$router.push({name: "CustomersDetails", params: {id: r.data.result.data.id}});
      }).catch((e) => {

        this.$store.commit("handleApiResponse", e.response);
      });
    }
  },
  created() {
    this.getPluginData()
  }
}
</script>
<style scoped>
</style>
