<template>
  <div class="card-header card-header-inline">
    <h6 class="editLine">Zeiterfassung</h6>
    <div class="btn-group float-end">
      <button class="btn btn-sm btn-outline-primary" @click="addTime = true"><i class="fa-duotone fa-stopwatch"></i>
        Aufwand hinzufügen
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="timeSheetList">
      <table class="table table-hover table-sm">
        <thead>
        <tr>
          <th style="width: 170px;">Datum</th>
          <th style="width: 78px;"><span tooltip="Stunden">{{taskActive.times.sumTask}}</span></th>
          <th tooltip="Bearbeiter" style="width: 75px;" class="center"><i class="fa-duotone fa-wrench"></i></th>
          <th>Kommentar</th>
          <th style="width: 20px;">&nbsp;</th>
          <th style="width: 80px;">&nbsp;</th>
        </tr>
        </thead>
        <tbody>

        <tr v-for="time in taskActive.times.task">

            <td>{{ $filters.formatDateTime(time.date) }}</td>
            <td>{{ time.hours }}</td>
            <td class="center"><img style="max-width: 40px" :src="$filters.employeeIcon(getEmployees, time.employee_id, 'id')" class="sketchAvatar" v-if="time.employee_id"></td>
            <td>{{ time.comment }}</td>
          <td ><span v-if="time.issue_id !== time.main_issue_id" tooltip="Aus Unteraufgabe"><i class="fa-duotone fa-list-tree"></i></span></td>
          <td class="center">

  <div class="btn-group">
  <button class="btn btn-sm btn-outline-primary" @click="editTime(time)"><span tooltip="Bearbeiten"></span><i class="fa-duotone fa-wrench"></i></button>
  <button class="btn btn-sm btn-outline-danger" @click="deleteTime(time.id)"><i class="fa-duotone fa-trash"></i></button>
</div>
</td>
        </tr>
        </tbody>

      </table>
    </div>
    <Modal v-if="addTime">
      <div class="modal show " style="display: block;">
        <div class="modal-dialog modal-lg purpleView">
          <div class="modal-content">
            <div class="modal-header">
              Aufwand hinzufügen
              <button class="btn btn-sm btn-outline-primary ml-auto" @click="postTime" :disabled="!time.activity_id || !time.hours || !time.date"><i
                class="fa-duotone fa-stopwatch"></i>
                Speichern
              </button>
              <button class="btn-close ms-3" @click="addTime = false"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-lg-6 col-12">
                  <label class="color">Stunden</label>
                  <input type="number" v-model="time.hours" class="form-control small" step="0.25"/>
                </div>
                <div class="col-lg-6 col-12">
                  <label class="color">Datum</label>
                  <VueDatePicker class="small" model-type="yyyy-MM-dd" format="dd.MM.yyyy"
                                 placeholder="Datum" :auto-apply="true" :close-on-auto-apply="true"
                                 locale="de" :enable-time-picker="false" cancel-text="X"
                                 :select-text="this.$t('globals.choose')"
                                 v-model="time.date"></VueDatePicker>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-8 col-12">
                  <label class="color">Kommentar</label>
                  <textarea v-model="time.comment" class="form-control small"></textarea>
                </div>
                <div class="col-lg-4 col-12">
                  <label class="color">Aktivität</label>
                  <select v-model="time.activity_id" class="form-select">
                    <option value="">Bitte wählen</option>
                    <option v-for="activity in getConfigs.issue_activities" :value="activity.id">{{ activity.title }}</option>
                  </select>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import VueDatePicker from "@vuepic/vue-datepicker";
import VueMultiselect from 'vue-multiselect';

export default {
  name: "Timesheet",
  props: {},
  components: {
    VueDatePicker, VueMultiselect
  },
  computed: {
    ...mapGetters(["modalsList", "taskActive", "getConfigs", "getEmployees"])
  },
  emits: ['update'],
  data() {
    return {
      file: '',
      addTime: false,
      time: {
        date: new Date().toISOString().substr(0, 10),
        hours: '1.00',
        activity_id: '',
        comment: '',
        id:''
      }
    };
  },
  methods: {
    postTime() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const payload = {
        date: this.time.date,
        hours: this.time.hours,
        activity_id: this.time.activity_id,
        comment: this.time.comment,
        issue: this.taskActive.id,
      };
      // if this.time.id add variable and put
      if(this.time.id) {

        axios.put(this.$store.state.apiUrl + "issue/" + this.$route.params.id + "/time/"+this.time.id , payload, {headers: headers})
          .then((response) => {
            this.addTime = false;

            // dispatch toast success
            this.$store.dispatch("toastSuccess", "Eintrag bearbeitet.");
            // reset Time back to defaults
            this.time = {
              date: new Date().toISOString().substr(0, 10),
              hours: '1.00',
              activity_id: '',
              comment: '',
              id:''
            };
            this.$emit("update");
          })
          .catch((error) => {
            this.$store.dispatch("handleApiResponse", error.response);
          });
      } else {
      axios.post(this.$store.state.apiUrl + "issue/" + this.$route.params.id + "/time", payload, {headers: headers})
        .then((response) => {
          this.addTime = false;

          // dispatch toast success
          this.$store.dispatch("toastSuccess", "Eintrag hinzugefügt.");
          // reset Time back to defaults
          this.time = {
            date: new Date().toISOString().substr(0, 10),
            hours: '1.00',
            activity_id: '',
            comment: '',
            id: ''
          };
          this.$emit("update");
        })
        .catch((error) => {
          this.$store.dispatch("handleApiResponse", error.response);
        });}
    },
    editTime(time) {
      this.time = time;
      this.addTime = true;
    },
    deleteTime(timeId) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.delete(this.$store.state.apiUrl + "issue/" + this.$route.params.id + "/time/" + timeId, {headers: headers})
        .then((response) => {

          // dispatch toast success
          this.$store.dispatch("toastSuccess", "Eintrag gelöscht.");
          this.$emit("update");
        })
        .catch((error) => {
          this.$store.dispatch("handleApiResponse", error.response);
        });
    },
  }
}
</script>
