<template>
  <div class="appView plugin-list">
    <List/>
  </div>
</template>

<script>

import axios from "axios";
import List from "./List.vue";
export default {

  name: 'SEPAOverview',

  data() {

    return {

    }

  },
components: {
    List
  },
  methods: {



  },

  created() {
  },

}

</script>
