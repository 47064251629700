<template>
  <div class="draft-item headline">
  <div class="draftLineWrap" >
    <div class="lineitem first l-checkbox">
      <span @click="emitSort('id')"  :class="(sortDirections.id) ? 'active' : ''">ID</span>
      <i v-if="sortDirections.id === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.id === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-title">
      <span @click="emitSort('title')"  :class="(sortDirections.title) ? 'active' : ''">Titel</span>
      <i v-if="sortDirections.title === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.title === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-category" >
      <span @click="emitSort('categoryTitle')"  :class="(sortDirections.categoryTitle) ? 'active' : ''">Kategorie</span>
      <i v-if="sortDirections.categoryTitle === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.categoryTitle === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-attachments" >
      <span>Anhang</span>
    </div>

    <div class="lineitem actions" >

    </div>
    <div class="lineitem l-actions ml-auto">

    </div>
    <div class="lineitem l-actions ">
      </div>
    <div class="lineitem last  ">

    </div>
  </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";


export default {
  name: "Sort",
  emits: ['sort'],
  data() {
    return {
      modalContent: null,
      toast: useToast(),
      address: {},
      sortDirections:{
        title: '',
        id: '',
        categoryTitle:'',

      }

    }
  },
  props: {
    draft: Object,
  },
  computed: {
    ...mapGetters(["draftActive", "getEmployees", "getConfigs"])
  },
  methods: {
    emitSort(clickedType) {
      // Reset all sort directions to the default state except for the clickedType
      for (let type in this.sortDirections) {
        if (type !== clickedType) {
          this.sortDirections[type] = '';
        }
      }

      // Toggle the clicked type's sort direction based on your logic
      if (this.sortDirections[clickedType] === '') {
        this.sortDirections[clickedType] = 'desc';
      } else if (this.sortDirections[clickedType] === 'desc') {
        this.sortDirections[clickedType] = 'asc';
      } else {
        this.sortDirections[clickedType] = '';
      }

      // Emit the clicked type and direction
      this.$emit('sort', { type: clickedType, direction: this.sortDirections[clickedType] });
    }
  },

}
</script>
<style lang="scss">
span.iconAction {
  font-size: 1rem;
  &.disabled {
    display: none;
  }
  border: 0 !important
}

</style>
