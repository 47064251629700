<template>
  <div class="card">

    <div class="card-body">
      <perfect-scrollbar style="max-height: 300px;">
        <div class="activityWrap" v-if="activities && activities.length > 0">
          <div class="row d-flex mb-3 pb-3" v-for="activity in activities" v-bind:key="activity.id">
            <div class="col-12  justify-content-center align-self-center">
              <p class="mb-0 font-weight-bold text-sm"> <img :src="$filters.employeeIcon(getEmployees, activity.u_id, 'u_id')" class="sketchAvatar"> {{ activity.formattedDate }} <span
                class="badge badge-primary ms-2">{{ activity.a_type }}</span></p>
              <p class="mb-0 text-xs" v-html="activity.a_description"></p>
            </div>

          </div>
        </div>
        <div v-else><p class="noItemsFound">{{$t("activity.noEntriesFound")}}</p></div>
      </perfect-scrollbar>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "CustomerActivities",
  computed: {
    ...mapGetters(["getEmployees", "customerActive"]),
  },
  props: {
    customer: {}
  },
  data() {
    return {
      activities: [],
    };
  },
  methods: {
    getActivities() {
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.customerActive.id + "/activities", {headers}).then((r) => {
        this.activities = r.data.result.data;
        this.loading = false;
      });
    }
  },
  created() {
    this.getActivities();
  },
  watch: {
    customerActive() {
      this.getActivities();
    }
  }
};
</script>
