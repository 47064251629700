<template>
  <div class=" card-body employee-list ">
    <EmployeeDetails v-if="$route.params.id" @close-employee="setEmployee(activeEmployee)" @closedEdit="handleClosedEdit" />
    <Sort @sort="resortOrder($event)" />
<!--    <div  class="product-item h-25" v-for="product in employee.data"-->
<!--          :key="product.id">-->
<!--      <Headline :employees="employees"/>-->
<!--    </div>-->

    <div  class="employee-item" v-for="employee in employees"
         :key="employee.id">
      <div class="employeeLineWrap" :class="(activeEmployee && activeEmployee.id === employee.id) ? 'active' : ''" v-if="checkAcl(employee)">
        <div class="lineitem first l-checkbox">
          <div class="checkbox">
            <input type="checkbox"
                   :id="'id_'+employee.id"
                   :value="employee.id"
                   v-if="employeeList && employeeList.selected"
                   v-model="employeeList.selected[employee.id]"/>
            <label :for="'id_'+employee.id"></label>
          </div>
        </div>
        <div class="lineitem l-title" @click="setEmployee(employee)">{{ employee.e_name }}</div>

        <div class="lineitem l-category" @click="setEmployee(employee)">{{ employee.category }}</div>
        <div class="lineitem l-avatar" @click="setEmployee(employee)">
          <img style="max-width: 40px" :src="$filters.employeeIcon(getEmployees, employee.id, 'id')" class="sketchAvatar" v-if="employee.id">
        </div>
        <div class="lineitem l-email ">
          <template v-if="employee.e_email">
            <i class="fa-duotone fa-envelope"></i> <span>{{ employee.e_email }}</span>
          </template>
        </div>
        <div class="lineitem l-phone l-title">
          <template v-if="employee.phone">
            <i class="fa-duotone fa-phone"></i> <span>{{ employee.phone }}</span>
          </template>
        </div>
        <div class="lineitem multiFa">
          <template v-if="employee._2fa === true">
        <span tooltip="2FA aktiviert" position="right">
        <i class="fa-duotone fa-shield-check  text-success"></i></span>
          </template>
          <template v-else>
         <span tooltip="2FA nicht aktiv" position="right">
        <i class="fa-duotone fa-shield text-secondary"></i></span>
          </template>
        </div>

        <div class="lineitem l-state">
          <template v-if="employee._block ==='1'">
            <span tooltip="Gesperrt" position="right">
        <i class="fa-duotone fa-circle-minus text-danger"></i></span>
          </template>
          <template v-else>
          <template v-if="employee.status === 'active'">
        <span  tooltip="Aktiv" position="right">
        <i class="fa-duotone fa-circle-check text-success"></i></span>
          </template>
          <template v-if="employee.status === 'inactive'">
        <span tooltip="Inaktiv" position="right">
        <i class="fa-duotone fa-circle-xmark text-secondary"></i></span>
          </template>
          <template v-if="employee.status === 'owner'">
        <span tooltip="owner" position="right">
        <i class="fa-duotone fa-circle-dollar"></i></span>
          </template>
  <template v-if="employee.status === 'issue'">
        <span tooltip="issue" position="right">
        <i class="fa-duotone fa-clipboard-list"></i></span>
          </template>

          <template v-if="employee.status === 'deleted'">
        <span tooltip="deleted" position="right">
        <i class="fa-duotone fa-circle-trash text-danger"></i></span>
          </template>
          </template>
        </div>
        <div class="lineitem l-issues">
          <template v-if="employee.use_in_issues === 'yes'">
        <span tooltip="Ticket-System aktiv" position="right">
        <i class="fa-duotone fa-clipboard-list" style="color: #6f42c1"></i></span>
          </template>
          <template v-else>
         <span tooltip="Ticket-System deaktiviert" position="right">
        <i class="fa-duotone fa-clipboard-list-check"  style="color: #c1afe3" ></i></span>
          </template>
        </div>


        <div class="lineitem last ml-auto">
          <template v-if="this.$route.params.id === employee.id">
            <i class="fa-duotone fa-x fa-swap-opacity" @click="$router.push({path: '/employees'})"></i>
          </template>
          <template v-else><i class="fa-duotone fa-chevrons-up" @click="setEmployee(employee)"></i></template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import EmployeeDetails from "@/routing/core/Employees/Details.vue";
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Employees/Layouts/Headline.vue";
import Sort from "@/routing/core/Employees/Layouts/Sort.vue";
import employee from "@/routing/core/Config/Employee.vue";
export default {
  name: "EmployeesOverview",
  components: {
    EmployeeDetails,
    Sort,
    Headline,
  },
  computed: {
    employee() {
      return employee
    },
    ...mapGetters(["getEmployees", "employeeList", "employeeActive", "getUser"])
  },

  methods: {
    checkAcl(employee){
    if(this.getUser && this.getUser.acl.employees.read === 'own'){
      if(this.getUser._isEmployee === employee.id){
        return true
      } else {
        return false
      }
    } else {
      return true;
    }
    },
    setEmployee(employee) {

      if(this.$route.params.id === null  || this.$route.params.id === employee.id ){
        this.$router.push({path: "/employees"});
        this.activeEmployee = null;
      } else {
        this.$router.push({name: "EmployeeDetails", params: {id: employee.id}});
        this.activeEmployee = employee;
      }
    },
    handleClosedEdit() {
      this.$router.push("/employees");
      this.loadEmployees();
    },
    loadEmployees() {

      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "employee/search", {headers}).then((r) => {
        this.employees = r.data.result.data;
        this.loading = false;
      });
    },
    resortOrder(payload) {
      if(payload.direction) {
        this.$store.state.filters.products.ordering.value = payload.type;
        this.$store.state.filters.products.direction.value = payload.direction;
      } else {
        this.$store.state.filters.products.ordering.value = payload.type
      }
      this.getProducts();
    },
  },
  data() {
    return {
      employees: [],
      activeEmployee: null,
      filter_query: '',
      filter_search: '',
      filter_category_id: '',
      filter_state: '',
    };
  },
  created() {
    if(this.$route.params.id){
      this.$router.push({name: "EmployeeDetails", params: {id: this.$route.params.id}});
    }
    this.loadEmployees();
    window.addEventListener('newEmployee', this.loadEmployees);
  },
};
</script>

