<template>
  <div class="row">
    <div class="col-12 mb-4">
      <div class="card">
        <h6 class="card-header">{{ $t("dashboard.cardHeader.customerEmployees") }}</h6>
        <!-- Tabs -->
        <ul class="tabNav" role="tablist">
          <li class="nav-item" :class="(activeEmployeeTab === 'contacts') ? 'active': ''">
            <a class="nav-link " @click="activeEmployeeTab = 'contacts'" role="tab">
              <i class="fa-duotone fa-users"></i>
              <br>
              <small>Kontakte</small>
            </a>
          </li>
          <li class="nav-item" :class="(activeEmployeeTab === 'leads') ? 'active': ''">
            <a class="nav-link" @click="activeEmployeeTab = 'leads'" role="tab">
              <i class="fa-duotone fa-file"></i>
              <br>
              <small>Qualifizierte Leads</small>
            </a>
          </li>
          <li class="nav-item" :class="(activeEmployeeTab === 'offers') ? 'active': ''">
            <a class="nav-link" @click="activeEmployeeTab = 'offers'" role="tab">
              <i class="fa-duotone fa-file"></i>
              <br>
              <small>Erstangebote</small>
            </a>
          </li>
          <li class="nav-item" :class="(activeEmployeeTab === 'orders') ? 'active': ''">
            <a class="nav-link" @click="activeEmployeeTab = 'orders'" role="tab">
              <i class="fa-file-contract fa-duotone" title="Aufträge"></i>
              <br>
              <small>Kunden</small>
            </a>
          </li>
        </ul>
        <statisticEmployeeTable v-if="activeEmployeeTab === 'contacts' && statsByEmployeeNews"
                                cType="filter_owner_id" :stats="statsByEmployeeNews"/>
        <statisticEmployeeTable v-if="activeEmployeeTab === 'leads' && statsByEmployeeLeads"
                                cType="filter_owner_id" :stats="statsByEmployeeLeads"/>
        <statisticEmployeeTable v-if="activeEmployeeTab === 'offers' && statsByEmployeeOffers"
                                cType="filter_owner_id" :stats="statsByEmployeeOffers"/>
        <statisticEmployeeTable v-if="activeEmployeeTab === 'orders' && statsByEmployeeOrders"
                                cType="filter_owner_id" :stats="statsByEmployeeOrders"/>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import statisticEmployeeTable from "@/routing/core/Dashboard/statistic/EmployeeTable";

export default {
  name: 'customer',
  components: {
    statisticEmployeeTable,
  },
  data() {
    return {
      activeEmployeeTab: 'contacts',
      statsByEmployeeNews: null,
      statsByEmployeeLeads: null,
      statsByEmployeeOffers: null,
      statsByEmployeeOrders: null,
      loading: false,
    }
  },
  props: {
    filter: {
      type: String,
      default: ""
    },
    options: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(["getMenu", "getConfigs"])
  },
  methods: {
    getStatsEmployees() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/customers/employees" + this.filter, {headers}).then((r) => {
        VueInner.statsByEmployeeNews = r.data.result.data.dataNews;
        VueInner.statsByEmployeeLeads = r.data.result.data.dataLeads;
        VueInner.statsByEmployeeOffers = r.data.result.data.dataOffers;
        VueInner.statsByEmployeeOrders = r.data.result.data.dataOrders;
        VueInner.loading = false;
      });
    },
  },
  watch: {
    filter: function (newVal, oldVal) {
      this.getStatsEmployees();
    },
  },
  created() {
    this.getStatsEmployees();
  },
}
</script>
