<template>
  <div class="table-responsive">
    <perfect-scrollbar style="max-height: 350px;">
    <table class="table align-items-center">
      <tbody>
      <tr v-for="(byType, index) in stats" :key="index">
        <td class="w-30">
          <div class="text-center">


              <button @click="filterCustomers(byType.type.id)" class="btn w-100 mb-0  text-sm btn-sm text-white" :style="{backgroundColor: (byType && byType.type && byType.type.color) ? byType.type.color : '#000'}"><template v-if="byType.type && byType.type.e_name">{{byType.type.e_name}}</template><template v-else>Unbekannt</template></button>

          </div>
        </td>
        <td>
          <div class="text-center">
            <p class="mb-0 text-xs font-weight-bold">Gesamt:</p>
            <h6 class="mb-0 text-sm">{{ byType.count }}</h6>
          </div>
        </td>
        <td>
          <div class="text-center">
            <p class="mb-0 text-xs font-weight-bold">Dieses Jahr:</p>
            <h6 class="mb-0 text-sm">{{byType.thisYear}}</h6>
          </div>
        </td>
        <td class="text-sm align-middle">
          <div class="text-center">
            <p class="mb-0 text-xs font-weight-bold">Vorletzter Monat:</p>
            <h6 class="mb-0 text-sm">{{byType.preLastMonth}}</h6>
          </div>
        </td>
        <td class="text-sm align-middle">
          <div class="text-center">
            <p class="mb-0 text-xs font-weight-bold">Letzter Monat:</p>
            <h6 class="mb-0 text-sm">{{byType.lastMonth}}</h6>
          </div>
        </td>
        <td class="text-sm align-middle">
          <div class="text-center">
            <p class="mb-0 text-xs font-weight-bold">Dieser Monat:</p>
            <h6 class="mb-0 text-sm">{{byType.thisMonth}}</h6>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    </perfect-scrollbar>
  </div>

</template>

<script>
export default {
  name: 'StatisticEmployeeTable',

  props: {
    stats : {},
    cType: {
      type: String
    },
  },
  methods: {
    filterCustomers($type) {
      let $route = '/customers';
      // add getParam

      $route += '?'+this.cType+'='+$type;

      this.$router.push($route);
    }
  }

}
</script>
