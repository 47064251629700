<template>
  <div class="card">
    <div class="card-body">
      <perfect-scrollbar style="max-height: 300px;">
        <div class="activityWrap" v-if="files && files.length > 0">
          <div class="row d-flex mb-3 pb-3" v-for="file in files" v-bind:key="file.id">
            <div class="col-5  justify-content-center align-self-center">
              {{ file.title }}
            </div>
            <div class="col-3">
              <span tooltip="hochgeladen am" position="right">{{ $filters.formatDateTime(file.uploaded_on) }}</span>
            </div>
            <div class="col-4">
              <div class="btn btn-outline-primary btn-sm float-end" @click="previewFile(file.id, file.type)"><i class="fa-duotone fa-eye"></i> Öffnen
              </div>
            </div>
          </div>

        </div>
        <div v-else><p class="noItemsFound">{{ $t("activity.noEntriesFound") }}</p></div>
      </perfect-scrollbar>
    </div>
  </div>
  <template v-if="file">
    <Modal v-if="modalsList.previewFile">
      <div class="modal show" style="display: block;">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              {{ file.title }}
              <button type="button" class="btn-close" @click="closeFile()"></button>
            </div>
            <div class="modal-body">
              <embed :src="'data:'+file.contentType+';base64,'+file.content" :style="(file.contentType === 'application/pdf') ? 'min-height:80vh;': ''">
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </template>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "CustomerFiles",
  computed: {
    ...mapGetters(["getEmployees", "customerActive", "modalsList"]),
  },
  props: {
    customer: {}
  },
  data() {
    return {
      files: [],
      file: '',
      fileInfo: {
        text: '',
        type: 'files'
      }
    };
  },
  methods: {
    getFiles() {
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.customerActive.id + "/files", {headers}).then((r) => {
        this.files = r.data.result.data;
        this.loading = false;
      });
    },
    closeFile() {
      this.file = '';
      this.$store.commit('closeModal', 'previewFile');
    },
    previewFile(attachmentId, type) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.customerActive.id + "/readFile/" + attachmentId + "/" + type, {headers: headers})
        .then((response) => {
          this.file = response.data.result.data;
          // show in modal
          this.$store.commit('openModal', 'previewFile');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  created() {
    this.getFiles();
  },
  watch: {
    customerActive() {
      this.getFiles();
    }
  }
};
</script>
