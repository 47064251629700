<template>
  <div class="row">
    <div class="col-12 mb-4">
      <BarChart v-if="barChartData && Object.keys(barChartData).length > 0" :chartData="barChartData"
                class="boxWhite" :options="barChartOptions"/>
    </div>
  </div>
</template>
<script >
import {BarChart} from "vue-chart-3";
import axios from "axios";
import {mapGetters} from "vuex";
export default {
  name: 'contacts',
  components: {
    BarChart,
  },
  data() {
    return {
      barChartData: {},
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            interaction: {}
          }
        },
        // add defining border to each stacked bar in the chart
      },
      loading: false,
    }
  },
  props: {
    filter: {
      type: String,
      default: ""
    },
    options: {
      type: Object

    }
  },
  computed: {
    ...mapGetters(["getMenu", "getConfigs"])
  },
  methods: {
    getStatsEmployees() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/customers/employees" + this.filter, {headers}).then((r) => {

        VueInner.barChartData = r.data.result.data.chart;
        VueInner.loading = false;
      });
    }
  },
  created() {
    this.getStatsEmployees();
  },
  watch: {
    filter: function (newVal, oldVal) {
      this.getStatsEmployees();
    }
  }
}
</script>
