<template>
  <Modal>
    <div class="modal show"
         aria-hidden="true" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            Passwort ändern
            <div class="btn-group float-end">
              <button type="button" class="btn btn-outline-secondary btn-sm" @click="$emit('close')"><i class="fa-duotone fa-x"></i> Abbrechen</button>
              <button type="button" class="btn btn-outline-primary btn-sm" @click="updatePassword"><i class="fa-duotone fa-floppy-disk"></i> Speichern</button>
            </div>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>Altes Passwort</label>
              <input type="password" class="form-control" placeholder="Altes Passwort" v-model="oldpassword">
            </div>
            <div class="form-group">
              <label>Neues Passwort</label>
              <input type="password" class="form-control" placeholder="Neues Passwort" v-model="newpassword">
            </div>
            <div class="form-group">
              <label>Neues Passwort bestätigen</label>
              <input type="password" class="form-control" placeholder="Neues Passwort bestätigen"
                     v-model="newpasswordverify">
            </div>
            <div class="form-group">
              <label>2FA Code</label>
              <input type="text" class="form-control" placeholder="2FA Code (falls aktiv)" v-model="twofactor">
              </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "Password",
  emits: ['close'],
  data() {
    return {
      showMenu: false,
      oldpassword: '',
      newpassword: '',
      newpasswordverify: '',
      twofactor: ''
    };
  },
  components: {},
  computed: {
    ...mapGetters(['getUser'])
  },
  methods: {
    updatePassword() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.state.apiUrl + "user/password" , {
        oldPassword: this.oldpassword,
        newPassword: this.newpassword,
        newPasswordVerify: this.newpasswordverify,
        twoFactor: this.twofactor
      }, {headers: headers})
        .then((response) => {
          if (response.data.result.success === true) {
            this.$emit('close');
          } else {
            this.$store.dispatch("handleApiResponse", response);
          }
        })
        .catch((error) => {
          this.$store.dispatch("handleApiResponse", error.response);
        });
    }
  },
};
</script>
<style>
.modal {
  z-index: 10000 !important;
}

.modal-backdrop {
  z-index: 9999 !important;
}
</style>
