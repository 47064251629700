<template>

  <div class="uploader">
    <UploadComponent />

<!--  TODO-ralf beim Customer zusätzlich direkt einbauen:  <UploadComponent :customerId="1333" />-->

    <div class="card">
      <h6 class="card-header"><i class="fa-duotone fa-file-upload"></i> Dateien</h6>


<List :files="tempFiles"/>
    </div>

  </div>

</template>
<script>
import UploadComponent from "@/directives/Upload/UploadComponent.vue";
import axios from "axios";
import List from "@/routing/core/Files/List.vue";
export default {
  name: "Files",
  components: {UploadComponent, List},
  data() {
    return {
      tempFiles: [],
      headers: {
        'crmAuth': localStorage.getItem('sketchManagerToken')
      },
    }
  },
  methods: {
    refreshTempFiles() {
      let headers = this.headers;
      axios.get(this.$store.state.apiUrl + 'files/uploadTemp', {headers}).then(response => {
        this.tempFiles = response.data;
      });
    }
  },
  created() {
    this.refreshTempFiles();
    window.addEventListener('triggerReloadFiles', this.refreshTempFiles);
  },
}
</script>
<style scoped>
</style>
