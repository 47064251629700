<template>
  <div class="eventLineWrap" :class="(eventActive && eventActive.id === event.id) ? 'active' : ''">
    <div class="d-flex">
    <div class="lineitem first l-checkbox">
      <div class="checkbox"><input type="checkbox" :id="'id_'+event.id" :value="event.id"/><label
        :for="'id_'+event.id"></label></div>
    </div>
    <div class="lineitem l-title" @click="setEvent(event)">
      {{ event.title }}
    </div>
    <div class="lineitem l-category">
      {{ event.category }}
    </div>
    <!--    <div class="lineitem l-attachments" >-->
    <!--      <template v-if="event._countAttachments && event._countAttachments > 0">-->
    <!--        <i class="fa-duotone fa-paperclip"></i><span> <span class="incNumber">{{ event._countAttachments }}</span></span>-->
    <!--      </template>-->
    <!--    </div>-->
    <div class="lineitem l-subevents" @click="setTask(task, 'subtasks')">
      <template v-if="event.subevents && event.subevents.length > 0">
        <i class="fa-duotone fa-list-tree"></i><span> <span class="incNumber">{{ event.subevents.length }}</span></span>
      </template>
    </div>
    <div class="lineitem l-date">
      <span class="date"><b>{{ $filters.formatDate(event.start) }}</b></span>
      <span class="time">&nbsp;| {{ $filters.formatTimeShort(event.start) }}</span>
    </div>
    <div class="lineitem l-state">
      <template v-if="event.state === '1'">
        <span  tooltip="Veröffentlicht" position="left">
        <i class="fa-duotone fa-circle-check"></i></span>
      </template>
      <template v-if="event.state === '0'">
        <span tooltip="Versteckt" position="left">
        <i class="fa-duotone fa-circle-xmark"></i></span>
      </template>
      <template v-if="event.state === '-2'">
        <span  tooltip="Papierkorb" position="left">
        <i class="fa-duotone fa-trash"></i></span>
      </template>
    </div>
    <div class="lineitem l-payment">
      <template v-if="event.show_payment !== null">
        <span :tooltip="$t('events.show_payment.' + event.show_payment)" position="left">
          <template v-if="event.show_payment === '1'">
            <i class="fa-duotone fa-user-unlock"></i>
          </template>
          <template v-if="event.show_payment === '2'">
            <i class="fa-duotone fa-user-lock"></i>
          </template>
          <template v-if="event.show_payment === '0'">
            <i class="fa-duotone fa-ban"></i>
          </template>
        </span>
      </template>
    </div>
    <div class="lineitem l-iconlist">
      <span :tooltip="'Plätze: '+event.quota" position="left" class="iconSpace">
        <i class="fa-duotone fa-person-seat"></i><span><span class="incNumber">{{ event.quota }}</span></span>
      </span>
      <span :tooltip="'Belegt: '+event.sumquota" position="left" class="iconSpace">
        <i class="fa-duotone fa-user-check"></i><span><span class="incNumber">{{ event.sumquota }}</span></span>
      </span>
      <span :tooltip="'Warteliste: '+event.sumwaiting" position="left" class="iconSpace">
        <i class="fa-duotone fa-user-clock"></i><span><span class="incNumber">{{ event.sumwaiting }}</span></span>
      </span>
      <span :tooltip="'Storno: '+event.sumstorno" position="left" class="iconSpace">
        <i class="fa-duotone fa-user-xmark"></i><span><span class="incNumber">{{ event.sumstorno }}</span></span>
      </span>
      <span :tooltip="'Abonnenten: '+event.sumabos" position="left" class="iconSpace">
        <i class="fa-duotone fa-user-gear"></i><span><span class="incNumber">{{ event.sumabos }}</span></span>
      </span>
    </div>
    <div class="lineitem l-tags">
      <span v-for="tag in event.tags">{{ tag.title }}</span>
    </div>
    <div class="lineitem last ml-auto">
      <template v-if=" eventActive && eventActive.id  === event.id">
        <i class="fa-duotone fa-x fa-swap-opacity" @click="$store.commit('closeEvent');"></i>
      </template>
      <template v-else><i class="fa-duotone fa-chevrons-up" @click="setEvent(event)"></i></template>
    </div>
  </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "Headline",
  props: {
    event: Object,
  },
  computed: {
    ...mapGetters(["eventActive", "getEmployees"])
  },
  methods: {
    setEvent(event, subview) {
      if (subview === undefined) {
        subview = "details";
      }
      // Unset if already set:
      if (this.eventActive === event.id) {
        // this.$store.dispatch('setTask', {})
        this.$router.push({name: "Events"});
      } else {
        this.$router.push({name: "EventTabs", params: {id: event.id, tab: subview}})
      }
    },
  }
}
</script>
