<template>
  <div class="row">
    <div class="col-lg-4 mb-lg">
      <div class="card z-index-2">
        <BarChart v-if="chartDataConversionRateComplete.labels" :chartData="chartDataConversionRateComplete"
                  class="boxWhite" :options='barHorizontalOptionsConversion'/>
      </div>
    </div>
    <template v-if="chartDataConversionRateYearly && Object.keys(chartDataConversionRateYearly).length > 0">
      <div class="col-lg-4 mb-lg" v-for="year in chartDataConversionRateYearly">
        <div class="card z-index-2">
          <BarChart v-if="year.labels" :chartData="year" class="boxWhite"
                    :options='barHorizontalOptionsConversion'/>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import {BarChart} from "vue-chart-3";
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: 'conversions',
  components: {
    BarChart
  },
  props: {
    filter: {
      type: String
    }
  },
  data() {
    return {
      chartDataConversionRateComplete: {},
      chartDataConversionRateYearly: {},
      barHorizontalOptionsConversion: {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
          },
        },
        scales: {
          y: {
            grid: {
              drawBorder: true,
              display: true,
              drawOnChartArea: true,
              drawTicks: true,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#ccc",
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
          x: {
            grid: {
              drawBorder: true,
              display: false,
              drawOnChartArea: true,
              drawTicks: true,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#ccc",
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
        },
      },
    }
  },
  methods: {
    getConversionRates() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/customers/conversionRates" + this.filter, {headers}).then((r) => {
        if (r.data.result && r.data.result.counter) {
          VueInner.chartDataConversionRateComplete = r.data.result.data.total;
          VueInner.chartDataConversionRateYearly = r.data.result.data.yearly;
        }
        VueInner.loading = false;
      });
    },
  },
  computed: {
    ...mapGetters(["getMenu", "getConfigs"])
  },
  created() {
    if (this.getMenu && this.getMenu.customers) {
      this.getConversionRates();
    }
  },
  watch: {
    filter: function (newVal, oldVal) {
      if (this.getMenu && this.getMenu.customers) {
        this.getConversionRates();
      }
    }
  }
}
</script>
