<template>
  <div class="card">
    <div class="card-header">
      <h6>{{ project.title }}
      <button type="button" class="btn-close float-end" aria-label="Close" @click="closeProject"></button></h6>
    </div>
    <div class="card-body">

      <div class="infoBlock"><i
        class="fa-duotone fa-calendar-circle-plus"></i> Erstellt: {{ $filters.formatDate(project.crm_created_date) }} |
        <i
          class="fa-duotone fa-calendar-circle-user"></i> Bearbeitet:
        {{ $filters.formatDate(project.crm_modified_date) }}
      </div>
      <p>{{ project.c_firstname }} {{ project.c_lastname }}
        <template v-if="project.c_company">- {{ project.c_company }}</template>
      </p>
      <Holzkraft :project="project" mode="show"  v-if="getPlugins && getPlugins.infos && getPlugins.infos.holzkraft" />
      <div class="projectDesc" v-if="project.description">
        <label class="color">Beschreibung</label>
        <div v-html="project.description"></div>
      </div>
      <hr>
      {{project}}
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Holzkraft from "@/routing/plugins/holzkraft/subs/projects";

export default {
  name: "Details",
  emits: ['refresh-project-list'],
  data() {
    return {
      project: {
        title: '',
        description: '',
        c_firstname: '',
        c_lastname: '',
        c_company: '',
        crm_created_date: '',
        crm_modified_date: '',
      }
    }
  },
  components: {
    Holzkraft
  },
  computed: {
    ...mapGetters(["projectActive", "getEmployees", "modalsList"])
  },
  methods:{
    closeProject() {
      this.$store.commit('closeProject');
      this.$router.push({name: 'Projects'});
    }
  },
  created() {
    if (this.projectActive) {
      this.project = this.projectActive;
    }
  }
}
</script>
<style scoped>
</style>
